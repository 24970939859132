// import React from "react";
// import { Link, Outlet } from "react-router-dom";

// const AdminLayout = () => {
//   return (
//     <div>
//       <header>
//         <nav>
//           <Link to="/admin/dashboard">Dashboard</Link>
//           <Link to="/admin/settings">Settings</Link>
//         </nav>
//       </header>
//       <main>
//         <Outlet />
//       </main>
//     </div>
//   );
// };

// export default AdminLayout;

import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Layout/Admin/Sidebar";
import AdminMain from "../../Pages/Admin/AdminMain";
import { Link, NavLink, Outlet } from "react-router-dom";

import { Helmet } from "react-helmet";
import { LogoImage } from "../Page/Common";
import Header from "./Admin/Header";
const AdminLayout = () => {
  const [toggle, setToggle] = useState(true);
  const Toggle = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <Header />
    </>
  );
};

export default AdminLayout;
