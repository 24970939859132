import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUserCourseQuizQuestionsQuery } from "../Apis/courseApi";
import { QuizView } from "../Components/Page/CourseQuiz";
import { MetaTag } from "../Components/Page/Common";
let quizImg = require("../Assets/Images/quizImg.jpg");
const QuizPlayView = () => {
  const { quizId, courseId } = useParams();
  const [showQuizQuestion, setShowQuizQuestion] = useState(false);
  const [userQuizAttemptID, setUserQuizAttemptID] = useState(0);

  const { data: crsQuizQuestions } = useGetUserCourseQuizQuestionsQuery(quizId);
  const onQuizRetakeHandler = (userQuizAttempId: number) => {
    setUserQuizAttemptID(userQuizAttempId);
    setShowQuizQuestion(false);
  };
  const navigate = useNavigate();
  return (
    <>
      <MetaTag title="Play Quiz - Learn & Code together" showAdScript={true} />
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6150950597865422"
        crossOrigin="anonymous"
      ></script>

      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-6150950597865422"
        data-ad-slot="3188949664"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      <div className="container mt-5 quiz-view">
        <h2 className="text-success text-center">
          {crsQuizQuestions?.result[0]?.quizTitle}
        </h2>
        <hr />

        {!showQuizQuestion && (
          <>
            <div className="d-flex justify-content-center">
              <img
                style={{ maxWidth: "400px", height: "auto" }}
                src={quizImg}
                alt="quizimg"
              />
            </div>
            <p className="text-center text-muted">
              {crsQuizQuestions?.result[0]?.lstQuestions.length} question
            </p>
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() => setShowQuizQuestion(true)}
                className="btn btn-success"
              >
                {/* <i className="bi bi-play-circle me-1"></i> */}
                <i className="fa-solid fa-play me-1"></i>
                Start test
              </button>
              <button
                onClick={() => navigate(-1)}
                className="btn btn-outline-secondary"
              >
                Cancel
              </button>
            </div>
          </>
        )}
        {showQuizQuestion && (
          <QuizView
            UserQuizAttemptID={userQuizAttemptID}
            quizData={crsQuizQuestions?.result[0]}
            onQuizRetak={(d: number) => onQuizRetakeHandler(d)}
          />
        )}
      </div>
    </>
  );
};

export default QuizPlayView;
