import React, { useState } from "react";
import { toastNotify } from "../../../Helper";
import { Form } from "react-bootstrap";
import QuizViewStat from "./QuizViewStat";
import { useNavigate } from "react-router-dom";
import { useAddUpdateUserQuizAttemptMutation } from "../../../Apis/courseApi";
import { withAuth } from "../../../HOC";
let _userAnswers: any[] = [];
const QuizView = (props: any) => {
  // let _userAnswers: any[] = [];
  // let _userAnswersData: any[] = [];
  const [currIndex, setCurrIndex] = useState(0);
  const [addUpdateUserQuiz] = useAddUpdateUserQuizAttemptMutation();
  // const [userAnswers, setUserAnswers] = useState<{
  //   courseQuestionId: number;
  //   userAnswerOptions: any;
  // }>({
  //   courseQuestionId: 0,
  //   userAnswerOptions: [],
  // });
  const [artists, setArtists] = useState<any>([]);
  const [userAnswersList, setUserAnswersList] = useState<any>([]);
  const [newuserAnswersList, setNewUserAnswersList] = useState<any>([]);
  const [userAnswers, setUserAnswers] = useState<any>(null);

  const [currCourseQuestionID, setCourseQuestionID] = useState(0);
  const [showQuizStat, setShowQuizStat] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [correctQuestionCount, setCorrectQuestionCount] = useState(0);
  const [userQuizAttemptID, setUserQuizAttemptID] = useState(
    props.UserQuizAttemptID
  );
  const navigate = useNavigate();
  const getQuizScore = (
    initailval: number,
    _correctCount: number,
    _userAnswersList: any
  ) => {
    let result = initailval;
    props.quizData?.lstQuestions.map((a: any) => {
      let _currAnswerOptions = a.answerOptions;

      let correctAnswerIds = _currAnswerOptions
        .filter((x: any) => x.isCorrect)
        .map((a: any) => a.answerId);
      let userAnswersIds = _userAnswersList
        .filter((x: any) => x.courseQuestionId === a.courseQuestionId)
        .map((a: any) => a.userAnswerOptions);
      if (
        JSON.stringify(correctAnswerIds) === JSON.stringify(userAnswersIds[0])
      ) {
        _correctCount++;
      }
      result = Math.round(
        (_correctCount / props.quizData?.lstQuestions.length) * 100
      );
    });
    return { CorrectCount: _correctCount, SCore: result };
  };
  let _currQuestionId = 0;

  const nextQuestionHandler = async (index: number) => {
    let _answer = _userAnswers;
    let currQuestionId = currCourseQuestionID;

    if (_answer.length === 0) {
      toastNotify("Please select option", "error");

      return;
    }
    // if (userAnswersList.length === 0) {
    //   toastNotify("Please select option", "error");

    //   return;
    // }

    // if (userAnswersList.userAnswerOptions.length <= 0) {
    //   toastNotify("Please select option", "error");

    //   return;
    // }
    if (currIndex > 0) {
      if (userAnswersList.courseQuestionId !== currQuestionId) {
        toastNotify("Please select option", "error");

        return;
      }
    }

    if (props.quizData?.lstQuestions.length === index + 1) {
      let newState = [...newuserAnswersList, userAnswersList];
      setNewUserAnswersList(newState);
      let quizObj = getQuizScore(0, 0, newState);
      let postData = {
        UserQuizAttemptId: props.quizData.userQuizAttemptId,

        CourseId: props.quizData.courseId,
        CourseQuizId: props.quizData.courseQuizId,
        AttemptJson: newState,
        TotalQuestion: props.quizData?.lstQuestions.length,
        TotalCorrect: quizObj.CorrectCount,
        TotalIncorrect:
          props.quizData?.lstQuestions.length - quizObj.CorrectCount,
        TotalScorePercent: quizObj.SCore,
      };
      let jsonData = JSON.stringify(postData);
      setIsLoading(true);
      let response: any = await addUpdateUserQuiz(jsonData);
      toastNotify("Quiz submitted successfully", "success");
      _userAnswers = [];
      setUserQuizAttemptID(response.data.result);
      setShowQuizStat(true);
      // await mutate(jsonData);

      // let newData = {
      //   data: coursePreviewData,
      //   UserQuizAttemptID: data[0].UserQuizAttemptID,
      //   AttemptJson: newState,
      //   AttemptCount: data[0].AttemptCount,
      // };
      // dispatch(courseActions.updateQuizContentStausForPreview(newData));
      // setUserQuizAttemptID(data[0].UserQuizAttemptID);
      // let msgData = {
      //   showMessage: true,
      //   messageId: 1,
      //   message: "Quiz Submitted",
      // };
      // dispatch(messageActions.showMessage(msgData));
      // let data = {
      //   quizData: postData,
      // };
      // setShowQuizStat(true);
      // if (quizObj.SCore == 100) {
      //   props.onQuizSubmit(data);
      // }
      return;
    } else {
      setCurrIndex(currIndex + 1);
    }
    if (props.quizData?.lstQuestions.length !== currIndex) {
      setCourseQuestionID(
        props.quizData?.lstQuestions[currIndex + 1].courseQuestionId
      );
    }

    let newState = [...newuserAnswersList, userAnswersList];
    setNewUserAnswersList(newState);
    setUserAnswers([]);
    //setCurrIndex(currIndex + 1);
  };

  const handleAnswerChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    opt: any
  ) => {
    let _newUserAnswers: any[] = [];
    // let a = e.target.checked;

    // let currQuestionId: number =
    //   props.quizData?.lstQuestions[currIndex].courseQuestionId;
    // if (a == true) {
    //   // if (artists !== null) {
    //   //   _userAnswersData = userAnswers;
    //   // }
    //   _userAnswers.push(opt.answerId);
    // } else {
    //   _userAnswers = _userAnswers.filter((x) => x !== opt.answerId);
    // }

    // // setUserAnswers(_userAnswers);
    // let userAnswerOptions = {
    //   courseQuestionId: currQuestionId,
    //   userAnswerOptions: _userAnswers,
    // };
    // setCourseQuestionID(currQuestionId);
    // _userAnswersData.push(userAnswerOptions)
    // // setUserAnswers([...userAnswers,userAnswerOptions]);
    // setArtists(_userAnswersData)

    let a = e.target.checked;

    let currQuestionId =
      props.quizData?.lstQuestions[currIndex].courseQuestionId;
    if (a == true) {
      if (userAnswers !== null) {
        _userAnswers = userAnswers;
      }
      _userAnswers.push(opt.answerId);
    } else {
      _userAnswers = _userAnswers.filter((x: any) => x !== opt.answerId);
    }

    setUserAnswers(_userAnswers);
    setCourseQuestionID(currQuestionId);
    let userAnswerOptions = {
      courseQuestionId: currQuestionId,
      userAnswerOptions: _userAnswers,
    };
    setUserAnswersList(userAnswerOptions);
  };
  const onQuizRetakeHandler = () => {
    setShowQuizStat(false);
    navigate(
      "/quiz/" +
        props.quizData.courseQuizId +
        "/view/" +
        props.quizData.courseId,
      { replace: true }
    );
  };
  return (
    <>
      {!showQuizStat && (
        <div>
          {
            //    AnswerOptions
            props.quizData?.lstQuestions.map((ques: any, index: number) => {
              if (index == currIndex) {
                return (
                  <>
                    <p
                      className="quiz-question"
                      dangerouslySetInnerHTML={{
                        __html: ques.question,
                      }}
                    ></p>
                    {ques.answerOptions.map((ans: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className=" d-flex justify-content-start align-items-center"
                        >
                          <div className="">
                            <Form.Check
                              id={`chk_${ans.answerId}_${ans.answer}`}
                              name={`chk_${ans.answerId}_${ans.answer}`}
                              label={ans.answer}
                              onChange={(e) => handleAnswerChange(e, ans)}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <br />
                    <span>{`${currIndex + 1} question of ${
                      props.quizData?.lstQuestions.length
                    }`}</span>
                    <br />
                    <div className="d-flex justify-content-start gap-2 mt-2">
                      <button
                        disabled={isLoading}
                        className="btn btn-outline-success "
                        onClick={() => nextQuestionHandler(index)}
                      >
                        {props.quizData?.lstQuestions.length === currIndex + 1
                          ? "Submit"
                          : " Next Question"}
                      </button>
                      <button
                        onClick={() => navigate(-1)}
                        className="btn btn-outline-secondary"
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                );
              }
            })
          }
        </div>
      )}
      {showQuizStat && (
        <QuizViewStat
          currUserQuizAttemptId={userQuizAttemptID}
          userAnswersList={newuserAnswersList}
          questions={props.quizData?.lstQuestions}
          onQuizRetake={props.onQuizRetak}
          onQuizPass={() =>
            navigate("/coursedetails/" + props.quizData.courseId)
          }
        />
      )}
    </>
  );
};

export default withAuth(QuizView);
