import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../Constants/apiUrl";
import { setLoggedInUser, emptyUserState } from "../Store/Redux/userAuthSlice";

import { toastNotify } from "../Helper";

// https://localhost:5001
const baseQuery = fetchBaseQuery({
  baseUrl: `${apiBaseUrl}/api`,
  prepareHeaders: (headers: Headers, api) => {
    const token = localStorage.getItem("token");
    token && headers.append("Authorization", "Bearer " + token);
    headers.append("Content-Type", "application/json");
    if (api.endpoint == "createBlog") {
      headers.delete("Content-Type");
    }
    if (api.endpoint == "updateBlog") {
      headers.delete("Content-Type");
    }
  },
});

const baseQueryWithInterceptor = async (
  args: any,
  api: any,
  extraOptions: any
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === "FETCH_ERROR") {
    // toastNotify("Check your internt connection or API is not running", "error");
    // window.location.href = "/login";
  }
  if (result.error && result.error.status === 401) {
    // Deal with unauthorised
    // useLogOut();
    toastNotify("Your session is expired", "error");
    const timer = setTimeout(() => {
      localStorage.removeItem("token");
      api.dispatch(setLoggedInUser({ ...emptyUserState }));
      window.location.href = "/login";
    }, 3000);
  }
  return result;
};
const blogApi = createApi({
  reducerPath: "blogApi",
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ["Blogs"],
  endpoints: (builder) => ({
    getBlogs: builder.query<
      any,
      { offset: number; limit: number; programType: string }
    >({
      query: (arg) => {
        const { offset, limit, programType } = arg;
        console.log("arg: ", arg);
        return {
          url: "blog/",
          params: { offset, limit, programType },
        };
      },
      providesTags: ["Blogs"],
    }),
    getBlogById: builder.query({
      query: (id) => ({
        url: `blog/${id}`,
      }),
      providesTags: ["Blogs"],
    }),
    createBlog: builder.mutation({
      query: (data) => ({
        url: "blog",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Blogs"],
    }),
    updateBlog: builder.mutation({
      query: ({ data, id }) => ({
        url: "blog/" + id,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Blogs"],
    }),
    deleteBlog: builder.mutation({
      query: ({ data, id }) => ({
        url: "blog/" + id,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Blogs"],
    }),

    createCourseQuestion: builder.mutation({
      query: (data) => ({
        url: "course/CreateCourseQuestion",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Blogs"],
    }),
    getCourseQuestionByCourseId: builder.query({
      query: (id) => ({
        url: `course/GetCourseQuestion/${id}`,
      }),
      providesTags: ["Blogs"],
    }),
    createCourseQuiz: builder.mutation({
      query: (data) => ({
        url: "course/CreateCourseQuiz",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Blogs"],
    }),
    getCourseQuizes: builder.query({
      query: () => ({
        url: "course/GetCourseQuizes",
      }),
      providesTags: ["Blogs"],
    }),
    getCourseQuizById: builder.query({
      query: (id) => ({
        url: `course/GetCourseQuizById/${id}`,
      }),
      providesTags: ["Blogs"],
    }),
    updateCourseQuiz: builder.mutation({
      query: ({ data, id }) => ({
        url: "course/UpdateCourseQuiz/" + id,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Blogs"],
    }),
    deleteCourseQuiz: builder.mutation({
      query: (id) => ({
        url: "course/DeleteCourseQuiz/" + id,
        method: "DELETE",
      }),
      invalidatesTags: ["Blogs"],
    }),
    getUserCourseQuizByCourseId: builder.query({
      query: (id) => ({
        url: `course/GetUserCourseQuizByCourseId/${id}`,
      }),
      providesTags: ["Blogs"],
    }),
    getUserCourseQuizQuestions: builder.query({
      query: (id) => ({
        url: `course/GetUserQuizQuestions/${id}`,
      }),
      providesTags: ["Blogs"],
    }),
    addUpdateUserQuizAttempt: builder.mutation({
      query: (data) => ({
        url: "course/AddUpdateUserQuizAttempt",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Blogs"],
    }),
    deleteCourseQuestion: builder.mutation({
      query: (id) => ({
        url: "course/DeleteCourseQuestion/" + id,
        method: "DELETE",
      }),
      invalidatesTags: ["Blogs"],
    }),
    getUserLeaderBoard: builder.query({
      query: () => ({
        url: `course/GetUserLeaderBoard`,
      }),
      providesTags: ["Blogs"],
    }),
    getRelatedBlogs: builder.query<
      any,
      { blogId: string | undefined; blogCategory: string }
    >({
      query: (arg) => {
        const { blogId, blogCategory } = arg;
        console.log("arg: ", arg);
        return {
          url: "blog/GetRelatedBlogs",
          params: { blogId, blogCategory },
        };
      },
      providesTags: ["Blogs"],
    }),
  }),
});

export const {
  useGetBlogsQuery,
  useGetBlogByIdQuery,
  useCreateBlogMutation,
  useUpdateBlogMutation,
  useDeleteBlogMutation,
  useCreateCourseQuestionMutation,
  useGetCourseQuestionByCourseIdQuery,
  useCreateCourseQuizMutation,
  useGetCourseQuizesQuery,
  useGetCourseQuizByIdQuery,
  useUpdateCourseQuizMutation,
  useDeleteCourseQuizMutation,
  useGetUserCourseQuizByCourseIdQuery,
  useGetUserCourseQuizQuestionsQuery,
  useAddUpdateUserQuizAttemptMutation,
  useDeleteCourseQuestionMutation,
  useGetUserLeaderBoardQuery,
  useGetRelatedBlogsQuery,
} = blogApi;
export default blogApi;
