import React, { useState } from "react";

import { inputHelper, toastNotify } from "../../Helper";
import { useRegisterUserMutation } from "../../Apis/authApi";
import { apiResponse } from "../../Interfaces";
import { NavLink, useNavigate } from "react-router-dom";
import { MetaTag, MiniLoader } from "../../Components/Page/Common";
import { Container, Row, Col, Button } from "react-bootstrap";
let loginImg = require("../../Assets/Images/register.png");

const Register = () => {
  const navigate = useNavigate();
  const [registerUser] = useRegisterUserMutation();
  const [loading, setLoading] = useState(false);
  const [useInput, setUserInput] = useState({
    userName: "",
    password: "",
    role: "",
    name: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    userName: "",
    name: "",
    password: "",
    confirmPassword: "",
    passWordLength: "",
  });
  const handleUserInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const tempData = inputHelper(e, useInput);
    setUserInput(tempData);
    validateInput(e);
  };

  const validateInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "userName":
          if (!value) {
            stateObj[name] = "Please enter email.";
          }
          break;
        case "name":
          if (!value) {
            stateObj[name] = "Please enter fullname.";
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (
            useInput.confirmPassword &&
            value !== useInput.confirmPassword
          ) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = useInput.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (useInput.password && value !== useInput.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    if (
      error.userName != "" ||
      error.name != "" ||
      error.password != "" ||
      error.confirmPassword != ""
    ) {
      setLoading(false);
      return;
    }
    if (useInput.password.length < 6) {
      toastNotify("Password must have 6 character length.", "error");
      setLoading(false);
      return;
    }
    const response: apiResponse = await registerUser({
      userName: useInput.userName,
      password: useInput.password,
      role: "customer",
      name: useInput.name,
    });
    if (response.data) {
      toastNotify("User registration successful!!");
      navigate("/login");
    } else if (response.error) {
      toastNotify(response.error.data.errorMessages[0], "error");
    }
    setLoading(false);
  };
  return (
    // <div className="container text-center">
    //   {loading && <MiniLoader />}
    //   <form onSubmit={handleSubmit} method="post">
    //     <h1 className="mt-5">Register</h1>
    //     <div className="mt-5">
    //       <div className="col-sm-6 offset-sm-3 col-xs-12 mt-4">
    //         <input
    //           type="text"
    //           className="form-control"
    //           placeholder="Enter Username"
    //           required
    //           name="userName"
    //           value={useInput.userName}
    //           onChange={handleUserInput}
    //         />
    //       </div>
    //       <div className="col-sm-6 offset-sm-3 col-xs-12 mt-4">
    //         <input
    //           type="text"
    //           className="form-control"
    //           placeholder="Enter Name"
    //           required
    //           name="name"
    //           value={useInput.name}
    //           onChange={handleUserInput}
    //         />
    //       </div>
    //       <div className="col-sm-6 offset-sm-3 col-xs-12 mt-4">
    //         <input
    //           type="password"
    //           className="form-control"
    //           placeholder="Enter Password"
    //           required
    //           name="password"
    //           value={useInput.password}
    //           onChange={handleUserInput}
    //         />
    //       </div>
    //       {/* <div className="col-sm-6 offset-sm-3 col-xs-12 mt-4">
    //         <select
    //           className="form-control form-select"
    //           required
    //           value={useInput.role}
    //           onChange={handleUserInput}
    //           name="role"
    //         >
    //           <option value="">--Select Role--</option>
    //           <option value={`${SD_Roles.CUSTOMER}`}>Customer</option>
    //           <option value={`${SD_Roles.ADMIN}`}>Admin</option>
    //         </select>
    //       </div> */}
    //     </div>
    //     <div className="mt-5">
    //       <button type="submit" disabled={loading} className="btn btn-success">
    //         Register
    //       </button>
    //     </div>
    //   </form>
    // </div>
    <>
      <MetaTag showAdScript={false} title="Register - Learn & Code together" />
      <div className="login-container">
        <Container>
          <Row className="d-flex align-items-center row flex-column-reverse flex-md-row">
            <Col md="6" sm="12">
              <form onSubmit={handleSubmit} method="post">
                <div className="form-header">
                  <div>
                    <h4 className="text-success fs-2">Register</h4>
                    <p className="text-muted">
                      Join to TheCodeHub to easily explore courses and improve
                      your skills
                    </p>
                  </div>
                  {loading && <MiniLoader />}
                </div>
                <div className="d-flex flex-column gap-4 mt-4">
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email"
                      required
                      name="userName"
                      value={useInput.userName}
                      onChange={handleUserInput}
                    />
                  </div>
                  {error.userName && (
                    <span className="text-danger">{error.userName}</span>
                  )}
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Fullname"
                      required
                      name="name"
                      value={useInput.name}
                      onChange={handleUserInput}
                    />
                  </div>
                  {error.name && (
                    <span className="text-danger">{error.name}</span>
                  )}
                  <div>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Password"
                      required
                      name="password"
                      value={useInput.password}
                      onChange={handleUserInput}
                    />
                  </div>
                  {error.password && (
                    <span className="text-danger">{error.password}</span>
                  )}

                  <div>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Confirm Password"
                      required
                      name="confirmPassword"
                      value={useInput.confirmPassword}
                      onChange={handleUserInput}
                    />
                  </div>
                  {error.confirmPassword && (
                    <span className="text-danger">{error.confirmPassword}</span>
                  )}
                </div>
                <div className="d-flex justify-content-between my-4 gap-2">
                  <Button
                    type="submit"
                    disabled={loading}
                    variant="success"
                    className="flex-grow-1"
                  >
                    Register
                  </Button>
                  <Button
                    onClick={() => navigate("/")}
                    variant="secondary"
                    className="flex-grow-1"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
              <span className="text-center">
                <p>
                  Already have account?{" "}
                  {/* <a onClick={() => navigate("/login")} variant="outline-success">
                  Login
                </a> */}
                  <NavLink to="/login">Login</NavLink>
                </p>
              </span>
            </Col>
            <Col md="6" sm="12">
              <img src={loginImg} alt="registerImg" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Register;
