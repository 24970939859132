import React, { useState } from "react";
import { inputHelper, toastNotify } from "../../Helper";
import { apiResponse, userModel } from "../../Interfaces";
import {
  useForgotPasswordMutation,
  useLoginUserMutation,
} from "../../Apis/authApi";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { setLoggedInUser } from "../../Store/Redux/userAuthSlice";

import { MetaTag, MiniLoader } from "../../Components/Page/Common";
import { Container, Row, Col, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
let loginImg = require("../../Assets/Images/login.png");

const ForgotPassword = () => {
  const [error, setError] = useState("");
  const [forgetPassword] = useForgotPasswordMutation();
  const [loading, setLoading] = useState(false);
  const [useInput, setUserInput] = useState({
    userName: "",
    password: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tempData = inputHelper(e, useInput);
    setUserInput(tempData);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const response: apiResponse = await forgetPassword({
      userName: useInput.userName,
    });
    if (response.data) {
      toastNotify(
        "New Password has been sent to your email address",
        "success"
      );
      navigate("/login");
    } else if (response.error) {
      console.log(response.error.data.errorMessages[0]);
      //setError(response.error.data.errorMessages[0]);
      toastNotify(response.error.data.errorMessages[0], "error");
    }
    setLoading(false);
  };
  return (
    <>
      <MetaTag
        showAdScript={false}
        title="Forgot Password - Learn & Code together"
      />

      <div className="forget-password-container d-flex">
        <Container>
          <div className="mt-5 col-12 col-lg-6">
            <form onSubmit={handleSubmit} method="post">
              <div className="form-header">
                <div>
                  <h4 className="text-success fs-2">Forgot Password</h4>
                </div>
                {loading && <MiniLoader />}
              </div>
              <div className="d-flex flex-column gap-4 mt-4">
                <div>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email"
                    required
                    name="userName"
                    value={useInput.userName}
                    onChange={handleUserInput}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start my-4 gap-2">
                {/* {error && <p className="text-danger">{error}</p>} */}
                <Button
                  type="submit"
                  disabled={loading}
                  variant="success"
                  className="flex-grow-1"
                >
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => navigate("/")}
                  className="flex-grow-1"
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
          {/* <Col md="6" sm="12">
            <img src={loginImg} />
          </Col> */}
        </Container>
      </div>
    </>
  );
};

export default ForgotPassword;
