import React from "react";
import { Link } from "react-router-dom";
import { courseModel } from "../../../Interfaces";
import { apiBaseUrl } from "../../../Constants/apiUrl";
interface Props {
  menuItem: courseModel;
}
const onImageError = (event: any) => {
  event.target.src = `${apiBaseUrl}/CourseImages/defaultCourse.png`;
};
const CourseCardItem = (props: Props) => {
  let _quizCountTxt = props.menuItem.quizCount <= 1 ? "Quiz" : "Quizes";
  return (
    <div className="col-md-3 col-12 my-3">
      <div className="course-card">
        <Link to={`coursedetails/${props.menuItem.courseId}`}>
          <img
            src={`${apiBaseUrl}/CourseImages/${props.menuItem.courseImage} `}
            alt={props.menuItem.courseName}
            className=" card-img-top"
            onError={(e) => onImageError(e)}
          />
        </Link>
        <div className="card-body mt-3">
          {/* <h5 className="card-title">Card title</h5> */}
          <Link
            style={{ textDecoration: "none", color: "green" }}
            to={`coursedetails/${props.menuItem.courseId}`}
          >
            <h3 className="card-title fs-5">{props.menuItem.courseName}</h3>
          </Link>
          <p className="card-text">{props.menuItem.courseDescription}</p>
        </div>

        <div className="card-footer pb-2">
          <Link
            to={`coursedetails/${props.menuItem.courseId}`}
            className="btn btn-sm btn-success"
          >
            Show more
          </Link>
          {/* <a href="#" className="card-link">
            Another link
          </a> */}
          <hr className="text-muted" />
          <span>
            <span>
              <i className="fa-solid fa-lightbulb text-muted me-2"></i>
              {/* <i className="bi bi-lightbulb-fill text-muted me-2"></i> */}
            </span>
            <span className="me-1 text-muted">{props.menuItem.quizCount}</span>
            <span className="text-muted">{_quizCountTxt}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CourseCardItem;
