import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCourseByIdQuery } from "../Apis/courseApi";

import { MainLoader, MetaTag } from "../Components/Page/Common";
import { blogModel, userModel } from "../Interfaces";

import { useSelector } from "react-redux";
import { RootState } from "../Store/Redux/store";
import { apiBaseUrl } from "../Constants";
import { CourseQuizList } from "../Components/Page/CourseQuiz";
import { useGetBlogByIdQuery, useGetRelatedBlogsQuery } from "../Apis/blogApi";
import { BlogCardItem, RealtedBlogCardItem } from "../Components/Page/Blog";
import moment from "moment";

//User ID c3f546a5-e560-4504-8521-ea4c6bc2d0a5
const BlogDetails = () => {
  const navigate = useNavigate();
  const { menuItemId } = useParams();
  const [blogItems, setBlogItems] = useState<blogModel[]>([]);
  const { data, isLoading, isError } = useGetBlogByIdQuery(menuItemId);
  const { data: relatedData, isLoading: relLoading } = useGetRelatedBlogsQuery(
    {
      blogId: menuItemId,
      blogCategory: data?.result?.blogCategory,
    },
    { skip: data == null }
  );
  // useEffect(() => {
  //   if (relatedData && relatedData.result) {

  //     setShowQuiz(true);
  //     setBlogItems(relatedData.result);
  //   }
  // }, [!relLoading, menuItemId]);
  const [showQuiz, setShowQuiz] = useState<boolean>(false);
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }, [!relLoading]);
  if (isLoading) {
    return (
      <div style={{ width: "100%" }} className="d-flex justify-content-center">
        <MainLoader />
      </div>
    );
  }
  if (isError) {
    return <div>Something went wrong!!</div>;
  }

  const onImageError = (event: any) => {
    event.target.src = `${apiBaseUrl}/BlogImages/defaultCourse.png`;
  };
  const onPlayQuizHandler = () => {
    if (!userData.id) {
      navigate("/login");
    } else {
      setShowQuiz(true);
    }
  };
  const getBlogDate = () => {
    const addedOn = moment(data.result?.addedOn).format("MMMM Do, YYYY");
    return addedOn;
  };
  return (
    <>
      <>
        <MetaTag
          showAdScript={true}
          title="Blog Details - Learn & Code together"
        />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6150950597865422"
          crossOrigin="anonymous"
        ></script>

        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-6150950597865422"
          data-ad-slot="3188949664"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        <div className="container pt-4 pt-md-5 blog-post">
          <div className="row d-flex flex-column flex-md-row">
            <div className="col-12 col-lg-9 blog-post-content">
              <img
                src={`${apiBaseUrl}/BlogImages/${data.result?.blogImgUrl} `}
                // style={{ borderRadius: "50%" }}
                alt={data.result?.blogName}
                onError={(e) => onImageError(e)}
                className="blog-header-img"
              />
              <h1 className="title text-success mt-3">
                {data.result?.blogName}
              </h1>
              <div className="d-flex justify-content-start gap-2">
                {data.result?.blogCategory && (
                  <span
                    className="badge text-bg-secondary py-2"
                    // style={{ height: "40px", fontSize: "20px" }}
                  >
                    {data.result?.blogCategory}
                  </span>
                )}
              </div>
              <div className="mt-3">
                <span>
                  <i className="fa-regular fa-clock text-muted me-2"></i>
                </span>

                <span className="text-muted">{getBlogDate()}</span>
              </div>

              {/* <span>
            <span
              className="badge text-bg-light pt-2"
              style={{ height: "40px", fontSize: "20px" }}
            >
              {data.result?.courseLevel}
            </span>
          </span> */}
              {/* <p style={{ fontSize: "20px" }} className="pt-2">
                  {data.result?.courseDescription}
                </p> */}
              <p
                style={{ fontSize: "16px" }}
                className="pt-2"
                dangerouslySetInnerHTML={{
                  __html: data.result?.blogDesc,
                }}
              ></p>
              {/* <span className="h3">${data.result?.price}</span> &nbsp;&nbsp;&nbsp;
          <span
            className="pb-2  p-3"
            style={{ border: "1px solid #333", borderRadius: "30px" }}
          >
            <i
              className="bi bi-dash p-1"
              style={{ fontSize: "25px", cursor: "pointer" }}
            ></i>
            <span className="h3 mt-3 px-3">{3}</span>
            <i
              className="bi bi-plus p-1"
              style={{ fontSize: "25px", cursor: "pointer" }}
            ></i>
          </span> */}
            </div>
            <div className="col-12 col-lg-3 mb-5">
              <h4 className="text-success sub-title">Related Blogs</h4>

              <div className="related-blog-sidebar">
                {" "}
                {relatedData?.result?.map(
                  (menuItem: blogModel, index: number) => (
                    <RealtedBlogCardItem menuItem={menuItem} key={index} />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default BlogDetails;
