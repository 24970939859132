import React, { useEffect, useState } from "react";
import {
  useCreateCourseMutation,
  useGetCourseByIdQuery,
  useUpdateCourseMutation,
} from "../Apis/courseApi";
import { inputHelper, toastNotify } from "../Helper";
import { useNavigate, useParams } from "react-router-dom";
import { MainLoader, MetaTag } from "../Components/Page/Common";
import {
  SD_Categories,
  SD_CourseLevel,
  SD_Program,
  SD_School_Class,
} from "../Utility/SD";
import { apiBaseUrl } from "../Constants/apiUrl";
import { userModel } from "../Interfaces";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store/Redux/store";
import ReactQuill from "react-quill";

import { withAuth } from "../HOC";
import { useUpdateProfileMutation } from "../Apis/authApi";
import { setLoggedInUser } from "../Store/Redux/userAuthSlice";
const Categories = [
  SD_Categories["C#"],
  SD_Categories.React,
  SD_Categories.SQL,
  SD_Categories.QA,
];
const Programs = [
  SD_Program.School,
  // SD_Program.College,
  SD_Program.InformationTechnology,
];
const ClassTypes: Array<SD_School_Class> = [
  SD_School_Class.Class1,
  SD_School_Class.Class2,
  SD_School_Class.Class3,
  SD_School_Class.Class4,
  SD_School_Class.Class5,
];
const Levels = [
  SD_CourseLevel.Beginner,
  SD_CourseLevel.Intermediate,
  SD_CourseLevel.Advanced,
];
const menuItemData = {
  fullName: "",
  email: "",
};

function ViewProfile() {
  const { id } = useParams();

  const navigate = useNavigate();
  const [imageToStore, setImageToStore] = useState<any>();
  const [imageToDisplay, setImageToDisplay] = useState<string>("");
  const [menuItemInputs, setMenuItemInputs] = useState(menuItemData);
  const [loading, setLoading] = useState(false);
  const [showClass, setShowClass] = useState(false);
  const [createMenuItem] = useCreateCourseMutation();
  const [updateMenuItem] = useUpdateProfileMutation();
  const [questionContent, setQuestionContent] = useState<string>("");
  const { data } = useGetCourseByIdQuery(id);
  const handleContentChange = (data: any) => {
    setQuestionContent(data.toString());
  };
  const dispatch = useDispatch();
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  useEffect(() => {
    const tempData = {
      fullName: userData.fullName,
      email: userData.email,
    };

    setMenuItemInputs(tempData);

    setImageToDisplay(userData.profileImage ? userData.profileImage : "");
  }, [userData]);

  const handleMenuItemInput = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (e.target.value == "School") {
      setShowClass(true);
    } else {
      setShowClass(false);
    }
    const tempData = inputHelper(e, menuItemInputs);
    setMenuItemInputs(tempData);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const imgType = file.type.split("/")[1];
      const validImgTypes = ["jpeg", "jpg", "png"];

      const isImageTypeValid = validImgTypes.filter((e) => {
        return e === imgType;
      });

      if (file.size > 1000 * 1024) {
        setImageToStore("");
        toastNotify("File Must be less then 1 MB", "error");
        return;
      } else if (isImageTypeValid.length === 0) {
        setImageToStore("");
        toastNotify("File Must be in jpeg, jpg or png", "error");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      setImageToStore(file);
      reader.onload = (e) => {
        const imgUrl = e.target?.result as string;

        console.log(imgUrl);
        setImageToDisplay(imgUrl);
      };
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    // if (!imageToStore && !id) {
    //   toastNotify("Please upload an image", "error");
    //   setLoading(false);
    //   return;
    // }

    const formData = new FormData();

    formData.append("FullName", menuItemInputs.fullName);
    formData.append("Email", menuItemInputs.email);

    if (imageToDisplay) formData.append("File", imageToStore);

    let response;

    response = await updateMenuItem({ data: formData });

    if (response) {
      toastNotify("Profile updated successfully", "success");
      let fullName = menuItemInputs.fullName;
      let email = menuItemInputs.email;
      let id = userData.id;
      let role = userData.role;
      let profileImage = imageToDisplay;
      localStorage.setItem("pImage", profileImage);
      localStorage.setItem("pFullName", fullName);
      dispatch(setLoggedInUser({ fullName, id, email, role, profileImage }));
      setLoading(false);
      navigate("/");
    }

    setLoading(false);
  };
  const onImageError = (event: any) => {
    event.target.src = `${apiBaseUrl}/ProfileImages/defaultUser.jpg`;
  };
  const getProfileImg = () => {
    let imgUrl = "";
    if (imageToDisplay.includes("base64")) {
      imgUrl = imageToDisplay;
    } else {
      imgUrl = apiBaseUrl + "/ProfileImages/" + userData.profileImage;
    }
    return imgUrl;
  };
  return (
    <>
      <MetaTag showAdScript={false} title="Profile - Learn & Code together" />
      <div className="container border mt-5 p-5 bg-light">
        {loading && <MainLoader />}
        <h3 className=" px-2 text-success">Update Profile</h3>

        <form
          method="post"
          encType="multipart/form-data"
          onSubmit={handleSubmit}
        >
          <div className="row mt-3">
            <div className="col-md-7">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                required
                name="fullName"
                value={menuItemInputs.fullName}
                onChange={handleMenuItemInput}
              />
              <input
                type="text"
                className="form-control mt-3"
                placeholder="Enter Email"
                required
                name="email"
                disabled
                readOnly
                value={menuItemInputs.email}
                onChange={handleMenuItemInput}
              />

              <input
                type="file"
                onChange={handleFileChange}
                className="form-control mt-3"
              />
              <div className="row">
                <div className="col-6">
                  <button
                    type="submit"
                    className="btn btn-success form-control mt-3"
                  >
                    Update
                  </button>
                </div>
                <div className="col-6">
                  <a
                    onClick={() => navigate("/")}
                    className="btn btn-secondary form-control mt-3"
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-5 text-center">
              <img
                onError={(e) => onImageError(e)}
                src={getProfileImg()}
                style={{ width: "100%", borderRadius: "30px" }}
                alt="profileImg"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default withAuth(ViewProfile);
