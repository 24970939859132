import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer, toast } from "react-toastify";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";

import { store } from "./Store";
import Dashboard from "./Pages/Dashboard";
import AdminTheme from "./Components/AdminTheme";
import ClientTheme from "./Components/ClientTheme";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
let dashPath = window.location.pathname.includes("/dashboard");
type Props = {
  children: React.ReactNode;
};
const ThemeSelector = ({ children }: Props) => {
  let path = window.location.pathname.includes("admin");

  return (
    <>
      <React.Suspense fallback={<></>}>
        {path && <AdminTheme />}
        {!path && <ClientTheme />}
      </React.Suspense>
      {children}
    </>
  );
};
root.render(
  <>
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer />

        <ThemeSelector>
          <App />
        </ThemeSelector>
      </BrowserRouter>
    </Provider>
  </>
);
