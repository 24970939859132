import React from "react";

import {
  useDeleteCourseMutation,
  useDeleteCourseQuizMutation,
  useGetCourseQuizesQuery,
  useGetCoursesQuery,
} from "../../../Apis/courseApi";
import { toast } from "react-toastify";
import { MainLoader } from "../../../Components/Page/Common";
import { courseModel, courseQuizModel } from "../../../Interfaces";
import { useNavigate } from "react-router";
import { apiBaseUrl } from "../../../Constants/apiUrl";
import { withAdminAuth } from "../../../HOC";
const QuizList = () => {
  const [deleteMenuItem] = useDeleteCourseQuizMutation();
  const { data, isLoading } = useGetCourseQuizesQuery(null);
  const navigate = useNavigate();

  const handleMenuItemDelete = async (id: number) => {
    toast.promise(
      deleteMenuItem(id),
      {
        pending: "Processing your request...",
        success: "Quiz Deleted Successfully 👌",
        error: "Error encoutnered 🤯",
      },
      {
        theme: "dark",
      }
    );
  };
  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="table p-5">
          <div className="d-flex align-items-center justify-content-between">
            <h1 className="text-success">Quiz List</h1>

            <button
              className="btn btn-success"
              onClick={() => navigate("/course/addquiz")}
            >
              Add New Quiz
            </button>
          </div>

          <div className="p-2">
            <div className="row border">
              <div className="col-1">Quiz ID</div>
              <div className="col-2">Name</div>
              <div className="col-2">Level</div>
              <div className="col-1">Course ID</div>
              <div className="col-2">Course Name</div>
              <div className="col-1">Action</div>
            </div>

            {data.result.map((menuItem: courseQuizModel) => {
              return (
                <div className="row border" key={menuItem.courseQuizId}>
                  <div className="col-1">{menuItem.courseQuizId}</div>
                  <div className="col-2">{menuItem.quizTitle}</div>
                  <div className="col-2">{menuItem.quizLevel}</div>
                  <div className="col-1">{menuItem.courseId}</div>
                  <div className="col-2">{menuItem.courseName}</div>
                  <div className="col-1">
                    <button className="btn btn-success">
                      <i
                        className="bi bi-pencil-fill"
                        onClick={() =>
                          navigate("/course/addquiz/" + menuItem.courseQuizId)
                        }
                      ></i>
                    </button>
                    <button
                      className="btn btn-danger mx-2"
                      onClick={() =>
                        handleMenuItemDelete(menuItem.courseQuizId)
                      }
                    >
                      <i className="bi bi-trash-fill"></i>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default withAdminAuth(QuizList);
