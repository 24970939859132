import React, { useState } from "react";
import Sidebar from "../../Components/Layout/Admin/Sidebar";
import AdminMain from "./AdminMain";

const AdminDashboard = () => {
  const [toggle, setToggle] = useState(true);
  const Toggle = () => {
    setToggle(!toggle);
  };
  let userImg = require("../../Assets/Images/user.png");
  return (
    <>
      <div className="content-wrapper-scroll">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-320"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="app-footer">
          <span>© Arise admin 2023</span>
        </div> */}
      </div>
    </>
  );
};

export default AdminDashboard;
