import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCourseByIdQuery } from "../Apis/courseApi";

import { MainLoader, MetaTag } from "../Components/Page/Common";
import { userModel } from "../Interfaces";

import { useSelector } from "react-redux";
import { RootState } from "../Store/Redux/store";
import { apiBaseUrl } from "../Constants";
import { CourseQuizList } from "../Components/Page/CourseQuiz";
//User ID c3f546a5-e560-4504-8521-ea4c6bc2d0a5
const CourseDetails = () => {
  const navigate = useNavigate();
  const { menuItemId } = useParams();
  const { data, isLoading, isError } = useGetCourseByIdQuery(menuItemId);

  const [showQuiz, setShowQuiz] = useState<boolean>(false);
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  if (isLoading) {
    return (
      <div style={{ width: "100%" }} className="d-flex justify-content-center">
        <MainLoader />
      </div>
    );
  }
  if (isError) {
    return <div>Something went wrong!!</div>;
  }
  const onImageError = (event: any) => {
    event.target.src = `${apiBaseUrl}/CourseImages/defaultCourse.png`;
  };
  const onPlayQuizHandler = () => {
    if (!userData.id) {
      navigate("/login");
    } else {
      setShowQuiz(true);
    }
  };
  return (
    <>
      <MetaTag
        title="Course Details - Learn & Code together"
        showAdScript={true}
      />
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6150950597865422"
        crossOrigin="anonymous"
      ></script>

      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-6150950597865422"
        data-ad-slot="3188949664"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>

      {showQuiz && (
        <CourseQuizList
          crsResult={data.result}
          onBack={() => setShowQuiz(false)}
        />
      )}
      {!showQuiz && (
        <>
          <div className="container pt-4 pt-md-5 course-detail">
            <div className="row d-flex flex-column-reverse flex-md-row">
              <div className="col-12 col-md-8">
                <h1 className="title text-success">
                  {data.result?.courseName}
                </h1>
                <div className="d-flex justify-content-start gap-2">
                  <span
                    className="badge text-bg-secondary py-2"
                    // style={{ height: "40px", fontSize: "20px" }}
                  >
                    {data.result?.programType}
                  </span>
                  {data.result?.courseCategory && (
                    <span
                      className="badge text-bg-secondary py-2"
                      // style={{ height: "40px", fontSize: "20px" }}
                    >
                      {data.result?.courseCategory}
                    </span>
                  )}
                  {data.result?.classType && (
                    <span
                      className="badge text-bg-secondary py-2"
                      // style={{ height: "40px", fontSize: "20px" }}
                    >
                      {data.result?.classType}
                    </span>
                  )}
                </div>
                {/* <span>
            <span
              className="badge text-bg-light pt-2"
              style={{ height: "40px", fontSize: "20px" }}
            >
              {data.result?.courseLevel}
            </span>
          </span> */}
                {/* <p style={{ fontSize: "20px" }} className="pt-2">
                  {data.result?.courseDescription}
                </p> */}
                <p
                  style={{ fontSize: "16px" }}
                  className="pt-2"
                  dangerouslySetInnerHTML={{
                    __html: data.result?.longCourseDescription,
                  }}
                ></p>
                {/* <span className="h3">${data.result?.price}</span> &nbsp;&nbsp;&nbsp;
          <span
            className="pb-2  p-3"
            style={{ border: "1px solid #333", borderRadius: "30px" }}
          >
            <i
              className="bi bi-dash p-1"
              style={{ fontSize: "25px", cursor: "pointer" }}
            ></i>
            <span className="h3 mt-3 px-3">{3}</span>
            <i
              className="bi bi-plus p-1"
              style={{ fontSize: "25px", cursor: "pointer" }}
            ></i>
          </span> */}
              </div>
              <div className="col-12 col-md-4 mb-5">
                <div className="course-detail-sidebar">
                  <img
                    src={`${apiBaseUrl}/CourseImages/${data.result?.courseImage} `}
                    // style={{ borderRadius: "50%" }}
                    alt={data.result?.courseName}
                    onError={(e) => onImageError(e)}
                  ></img>
                  <div className="d-flex justify-content-between gap-2 mt-2">
                    <button
                      onClick={onPlayQuizHandler}
                      className="btn btn-success form-control"
                    >
                      {/* <i className="bi bi-lightbulb-fill me-2"></i> */}
                      <i className="fa-solid fa-lightbulb  me-2"></i>
                      Play Quiz
                    </button>
                    <button
                      onClick={() => navigate(-1)}
                      className="btn btn-outline-secondary form-control"
                    >
                      Back to Home
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CourseDetails;
