import React, { useState } from "react";
import { MainLoader } from "../Common";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateCourseMutation,
  useCreateCourseQuestionMutation,
  useGetCourseByIdQuery,
  useUpdateCourseMutation,
} from "../../../Apis/courseApi";
import Form from "react-bootstrap/Form";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { AiOutlineDelete } from "react-icons/ai";
import { SD_QuizTypes } from "../../../Utility/SD";
import { isEmpty } from "../../../Helper/validationHelper";
import { toastNotify } from "../../../Helper";
import { parse } from "gift-pegjs";

const QuizQuestionPage = (props: any) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [imageToStore, setImageToStore] = useState<any>();
  const [imageToDisplay, setImageToDisplay] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const [createMenuItem] = useCreateCourseMutation();
  const [updateMenuItem] = useUpdateCourseMutation();
  const { data } = useGetCourseByIdQuery(id);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [showQuizQuestion, setShowQuizQuestion] = useState(false);
  const [questionContent, setQuestionContent] = useState(
    props.question ? props.question.questionHtml : ""
  );
  const [answers, setAnswers] = useState(
    props.question
      ? props.question.answerOptions
      : [{ answerId: 0, answer: "", isCorret: false }]
  );
  const [createCourseQuestion] = useCreateCourseQuestionMutation();
  const handleContentChange = (data: any) => {
    setQuestionContent(data.toString());
  };
  const handleQuestionContentChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setQuestionContent(event.target.value);
  };

  const handleFormChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    index: number
  ) => {
    let data = [...answers];
    data = data.map((c, idx) => {
      return idx === index ? { ...c, answer: event.target.value } : c;
    });

    //data[index][event.target.name] = event.target.value;
    setAnswers(data);
  };

  const handleAnswerChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let a = e;
    let data = [...answers];
    //data[index].answerId = index;
    data[index].isCorrect = a.target.checked;
    setAnswers(data);
  };
  const handleAddAnswer = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let stateFields = answers;

    let newfield;
    //let max = 0;
    if (stateFields.length === 0) {
      newfield = { answerId: 0, answer: "", isCorret: false };
    } else {
      const maxAnswerId = stateFields.reduce(
        (max: number, character: any) =>
          character.answerId > max ? character.answerId : max,
        stateFields[0].answerId
      );
      newfield = { answerId: maxAnswerId + 1, answer: "", isCorret: false };
    }

    setAnswers([...answers, newfield]);
  };
  const removeFields = (index: number) => {
    let data = [...answers];
    data.splice(index, 1);
    setAnswers(data);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    if (
      props.quizVal.quizType == SD_QuizTypes["GIFT Format Multiple Question"]
    ) {
      if (questionContent == "") {
        toastNotify("Question cannot be empty", "error");
        return;
      }
      let _isError = false;
      let questionsData: any = [];
      try {
        let ques = `${questionContent}`;
        const quiz1 = parse(ques);

        quiz1.map((a) => {
          if (a.type !== "MC") {
            _isError = true;
            toastNotify(
              "Question type should be multiple choice or Invalid GIFT question format!!",
              "error"
            );
            setLoading(false);
            return;
          }
          if (isEmpty(a.stem.text)) {
            _isError = true;

            toastNotify("Question cannot be empty!!", "error");
            setLoading(false);
            return;
          }
          if (a.choices.length === 0) {
            _isError = true;

            toastNotify("Answers cannot be empty!!", "error");
            setLoading(false);
            return;
          }
          const uniqueValues = new Set(
            a.choices.map((v) => v.text.text.toLowerCase().trim())
          );
          if (uniqueValues.size < a.choices.length) {
            _isError = true;

            toastNotify("Answers must be unique!!", "error");
            setLoading(false);
            return;
          }
          if (a.choices.filter((a) => a.isCorrect === true).length === 0) {
            _isError = true;

            toastNotify(
              "Answers must have at least one correct answer!!",
              "error"
            );
            setLoading(false);
            return;
          }
          questionsData.push({
            Question: a.stem.text,
            AnswerOptions: a.choices.map((b, index) => {
              return {
                AnswerId: index,
                Answer: b.text.text,
                IsCorrect: b.isCorrect,
              };
            }),
          });
        });
      } catch (ex) {
        _isError = true;

        toastNotify("Please enter valid GIFT format questions!!", "error");
        setLoading(false);
        return;
      }
      if (!_isError) {
        let questions = {
          CourseQuestionId:
            typeof props.question === "undefined" || props.question === null
              ? 0
              : props.question.courseQuestionId,
          QuestionTypeId:
            typeof props.question === "undefined" || props.question === null
              ? SD_QuizTypes["Add Multiple Choice Question"]
              : props.question.questionTypeId,

          PayloadStrings: questionsData,
          CourseId: props.quizVal.courseId,
        };

        let postData = JSON.stringify(questions);
        let response;
        response = await createCourseQuestion(postData);
        toastNotify("Menu Item created successfully", "success");
        setLoading(false);
        var newData = {
          questionData: response,
          quizData: props.quizVal,
        };
        props.onSaveQuestion(newData);
      }
    } else {
      let newAnswers: any = [];
      answers.map((a: any, index: number) => {
        if (!isEmpty(a.answer)) {
          newAnswers.push({
            answerId: index,
            answer: a.answer,
            isCorrect: a.isCorrect,
          });
        }
      });
      console.log(newAnswers);
      let questionsData = [];
      try {
        if (isEmpty(questionContent)) {
          toastNotify("Question cannot be empty", "error");
          setLoading(false);
          return;
        }
        if (newAnswers.length === 0) {
          toastNotify("Answers cannot be empty", "error");
          setLoading(false);
          return;
        }
        const uniqueValues = new Set(
          newAnswers.map((v: any) => v.answer.toLowerCase().trim())
        );
        if (uniqueValues.size < newAnswers.length) {
          toastNotify("Answers must be unique", "error");
          setLoading(false);
          return;
        }
        if (newAnswers.filter((a: any) => a.isCorrect === true).length === 0) {
          toastNotify("Answers must have at least one correct answer", "error");
          setLoading(false);
          return;
        }
        questionsData.push({
          Question: questionContent,
          AnswerOptions: newAnswers.map((b: any, index: number) => {
            return {
              answerId: index,
              answer: b.answer,
              isCorrect: b.isCorrect,
            };
          }),
        });
      } catch (ex) {
        toastNotify("Something went wrong", "error");
        setLoading(false);
        return;
      }
      let questions = {
        CourseQuestionId:
          typeof props.question === "undefined" || props.question === null
            ? 0
            : props.question.courseQuestionId,
        QuestionTypeId:
          typeof props.question === "undefined" || props.question === null
            ? SD_QuizTypes["Add Multiple Choice Question"]
            : props.question.questionTypeId,

        PayloadStrings: questionsData,
        CourseId: props.quizVal.courseId,
      };
      let postData = JSON.stringify(questions);
      let response;
      response = await createCourseQuestion(postData);
      toastNotify("Menu Item created successfully", "success");
      setLoading(false);
      var newData = {
        questionData: response,
        quizData: props.quizVal,
      };
      props.onSaveQuestion(newData);
    }
  };
  const getQuestionUI = () => {
    let UI: any;
    let aa = props.quizVal.quizType;
    let bb = SD_QuizTypes["Add Multiple Choice Question"];

    if (props.question?.courseQuestionId > 0) {
      UI = (
        <>
          {" "}
          <div className="col-md-7">
            <ReactQuill
              className="editor"
              theme="snow"
              value={questionContent}
              id="questionContent"
              onChange={(e) => {
                handleContentChange(e);
              }}
            />

            <h6>Answers</h6>
            {answers.map((ans: any, index: number) => {
              return (
                <div
                  key={index}
                  className="row gy-2 gx-3 align-items-center mb-3"
                >
                  <div className="col">
                    <input
                      name="answer"
                      placeholder="Answer"
                      value={ans.answer}
                      onChange={(event) => handleFormChange(event, index)}
                      className="form-control"
                    />
                  </div>
                  <div className="col-auto">
                    <Form.Check
                      id={`chk_${ans.answerId}_${ans.answer}`}
                      name={`chk_${ans.answerId}_${ans.answer}`}
                      onChange={(e) => handleAnswerChange(e, index)}
                      label="Correct"
                      checked={ans.isCorrect}
                    />
                  </div>
                  <div className="col-auto">
                    <span
                      className="btn btn-secondary btn-sm"
                      onClick={() => removeFields(index)}
                    >
                      <AiOutlineDelete />
                    </span>
                  </div>
                </div>
              );
            })}
            <button
              onClick={(e) => handleAddAnswer(e)}
              className="btn btn-secondary"
            >
              Add answer
            </button>

            <div className="row">
              <div className="col-6">
                <button
                  type="submit"
                  className="btn btn-success form-control mt-3"
                >
                  Save
                </button>
              </div>
              <div className="col-6">
                <a
                  onClick={() => props.onCancel()}
                  className="btn btn-secondary form-control mt-3"
                >
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      switch (parseInt(props.quizVal.quizType)) {
        case SD_QuizTypes["Add Multiple Choice Question"]:
          UI = (
            <>
              {" "}
              <div className="col-md-7">
                <ReactQuill
                  className="editor"
                  theme="snow"
                  value={questionContent}
                  id="questionContent"
                  onChange={(e) => {
                    handleContentChange(e);
                  }}
                />

                <h6>Answers</h6>
                {answers.map((ans: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="row gy-2 gx-3 align-items-center mb-3"
                    >
                      <div className="col">
                        <input
                          name="answer"
                          placeholder="Answer"
                          value={ans.answer}
                          onChange={(event) => handleFormChange(event, index)}
                          className="form-control"
                        />
                      </div>
                      <div className="col-auto">
                        <Form.Check
                          id={`chk_${ans.answerId}_${ans.answer}`}
                          name={`chk_${ans.answerId}_${ans.answer}`}
                          onChange={(e) => handleAnswerChange(e, index)}
                          label="Correct"
                          checked={ans.isCorrect}
                        />
                      </div>
                      <div className="col-auto">
                        <span
                          className="btn btn-secondary btn-sm"
                          onClick={() => removeFields(index)}
                        >
                          <AiOutlineDelete />
                        </span>
                      </div>
                    </div>
                  );
                })}
                <button
                  onClick={(e) => handleAddAnswer(e)}
                  className="btn btn-secondary"
                >
                  Add answer
                </button>

                <div className="row">
                  <div className="col-6">
                    <button
                      type="submit"
                      className="btn btn-success form-control mt-3"
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-6">
                    <a
                      onClick={() => props.onCancel()}
                      className="btn btn-secondary form-control mt-3"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </>
          );
          break;

        case SD_QuizTypes["GIFT Format Multiple Question"]:
          UI = (
            <>
              {" "}
              <>
                {" "}
                <div className="col-md-7">
                  <textarea
                    className="form-control"
                    value={questionContent}
                    id="questionTxtArea"
                    onChange={(e) => handleQuestionContentChange(e)}
                    rows={12}
                  />
                  {`Note:Question format must be same as below:
  
                   Your favorite tech? {
                      ~Angular
                      ~React
                      =Node
                      ~Next
                    }`}

                  <div className="row">
                    <div className="col-6">
                      <button
                        type="submit"
                        className="btn btn-success form-control mt-3"
                      >
                        Save
                      </button>
                    </div>
                    <div className="col-6">
                      <a
                        onClick={() => props.onCancel()}
                        className="btn btn-secondary form-control mt-3"
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </>
            </>
          );
          break;
      }
    }

    return UI;
  };
  return (
    <div className="container border mt-5 p-5 bg-light">
      {loading && <MainLoader />}
      <h3 className=" px-2 text-success">
        {id
          ? "Edit Course"
          : ` ${
              props.quizType == SD_QuizTypes["Add Multiple Choice Question"]
                ? "Add Multiple Question"
                : ""
            }`}
      </h3>

      <form method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
        <div className="row mt-3">
          {getQuestionUI()}
          <div className="col-md-5 text-center">
            {/* <img
              src={`${apiBaseUrl}/CourseImages/${imageToDisplay} `}
              style={{ width: "100%", borderRadius: "30px" }}
              alt=""
            /> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default QuizQuestionPage;
