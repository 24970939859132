import { useGetUserCourseQuizQuestionsQuery } from "../../../Apis/courseApi";
import { MainLoader } from "../Common";
import QuizViewStat from "./QuizViewStat";

const LearnerQuizViewStat = (props: any) => {
  const { data: crsQuizQuestions, isLoading } =
    useGetUserCourseQuizQuestionsQuery(props.quizId);

  const getUserAnswerOptions = (lst: any) => {
    let data = JSON.parse(lst);
    let newData: any = [];
    data.map((a: any) => {
      newData.push({
        courseQuestionId: a.CourseQuestionId,
        userAnswerOptions: a.UserAnswerOptions,
      });
    });

    return newData;
  };
  return (
    <div>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="container">
          <h2 className="text-success text-center">{props.quizTitle}</h2>
          <hr />
          <QuizViewStat
            onBack={props.onBack}
            userAnswersList={getUserAnswerOptions(
              crsQuizQuestions?.result[0].attemptJson
            )}
            questions={crsQuizQuestions?.result[0].lstQuestions}
            onQuizRetake={props.onQuizRetak}
          />
        </div>
      )}
    </div>
  );
};

export default LearnerQuizViewStat;
