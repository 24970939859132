import { configureStore } from "@reduxjs/toolkit";
import { courseReducer } from "./courseSlice";
import { courseApi, authApi } from "../../Apis";
import { userAuthReducer } from "./userAuthSlice";
import interviewApi from "../../Apis/interviewApi";
import blogApi from "../../Apis/blogApi";
const store = configureStore({
  reducer: {
    courseStore: courseReducer,
    userAuthStore: userAuthReducer,
    [courseApi.reducerPath]: courseApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [interviewApi.reducerPath]: interviewApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
  },
  middleware: (getDefaultMiddeleWare) =>
    getDefaultMiddeleWare()
      .concat(courseApi.middleware)
      .concat(authApi.middleware)
      .concat(interviewApi.middleware)
      .concat(blogApi.middleware),
  //   .concat(shoppingCartApi.middleware)
  //  .concat(authApi.middleware)
  //   .concat(orderApi.middleware)
  //   .concat(paymentApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
