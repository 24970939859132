import { Pagination } from "react-bootstrap";
import { SD_Pagination } from "../../../Utility/SD";
const PaginationPage = (props: any) => {
  const { currentPage, maxPageLimit, minPageLimit } = props;

  const _totalPages = props.response[0].rowTotal;
  const totalPages = Math.ceil(_totalPages / SD_Pagination.PageSize);

  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }
  const handlePrevClick = () => {
    props.onPrevClick();
  };
  const handleNextClick = () => {
    props.onNextClick();
  };
  const handlePageClick = (e: any) => {
    props.onPageChange(Number(e.target.id));
  };
  const pageNumbers = pages.map((page) => {
    if (page <= maxPageLimit && page > minPageLimit) {
      return (
        // <li
        //   key={page}
        //   id={page.toString()}
        //   className={currentPage === page ? "active" : "inactive"}
        //   onClick={handlePageClick}
        // >
        //   {page}
        // </li>
        <Pagination.Item
          key={page}
          id={page.toString()}
          className={currentPage === page ? "active" : "inactive"}
          onClick={handlePageClick}
        >
          {page}
        </Pagination.Item>
      );
    } else {
      return null;
    }
  });
  let pageIncrementEllipses = null;
  if (pages.length > maxPageLimit) {
    pageIncrementEllipses = <Pagination.Ellipsis onClick={handleNextClick} />;
  }
  let pageDecremenEllipses = null;
  if (minPageLimit >= 1) {
    pageDecremenEllipses = <Pagination.Ellipsis onClick={handlePrevClick} />;
  }
  return (
    <div>
      {" "}
      <Pagination className="custom-pagination">
        {/* <Pagination.First /> */}
        <Pagination.Prev
          onClick={handlePrevClick}
          disabled={currentPage === pages[0]}
        />

        {pageDecremenEllipses}
        {pageNumbers}
        {pageIncrementEllipses}

        <Pagination.Next
          onClick={handleNextClick}
          disabled={currentPage === pages[pages.length - 1]}
        />
        {/* <Pagination.Last /> */}
      </Pagination>
      {/* <ul className="pageNumbers">
        <li>
          <button onClick={handlePrevClick} disabled={currentPage === pages[0]}>
            Prev
          </button>
        </li>
        {pageDecremenEllipses}
        {pageNumbers}
        {pageIncrementEllipses}
        <li>
          <button
            onClick={handleNextClick}
            disabled={currentPage === pages[pages.length - 1]}
          >
            Next
          </button>
        </li>
      </ul> */}
    </div>
  );
};

export default PaginationPage;
