import React from "react";
import {
  useDeleteCourseMutation,
  useGetCoursesQuery,
} from "../../../Apis/courseApi";
import { toast } from "react-toastify";
import { MainLoader } from "../../../Components/Page/Common";
import { courseModel, userModel } from "../../../Interfaces";
import { useNavigate } from "react-router";
import { apiBaseUrl } from "../../../Constants/apiUrl";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/Redux/store";
import { withAdminAuth } from "../../../HOC";

function CourseList() {
  const [deleteMenuItem] = useDeleteCourseMutation();
  const { data, isLoading } = useGetCoursesQuery({
    offset: 1,
    limit: 100,
    programType: "",
  });
  const navigate = useNavigate();
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  const handleMenuItemDelete = async (id: number) => {
    let postdata = {
      UserId: userData.id,
    };
    toast.promise(
      deleteMenuItem({ data: postdata, id }),
      {
        pending: "Processing your request...",
        success: "Menu Item Deleted Successfully 👌",
        error: "Error encoutnered 🤯",
      },
      {
        theme: "dark",
      }
    );
  };

  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="table p-5">
          <div className="d-flex align-items-center justify-content-between">
            <h1 className="text-success">Course List</h1>

            <button
              className="btn btn-success"
              onClick={() => navigate("/course/addcourse")}
            >
              Add New Course
            </button>
          </div>

          <div className="p-2">
            <div className="row border">
              <div className="col-1">Image</div>
              <div className="col-1">ID</div>
              <div className="col-2">Name</div>
              <div className="col-2">Description</div>
              <div className="col-1">Category</div>
              <div className="col-2">Level</div>
              <div className="col-1">Action</div>
            </div>

            {data.result.map((menuItem: courseModel) => {
              return (
                <div className="row border" key={menuItem.courseId}>
                  <div className="col-1">
                    <img
                      src={`${apiBaseUrl}/CourseImages/${menuItem.courseImage} `}
                      alt={menuItem.courseName}
                      style={{ width: "100%", maxWidth: "120px" }}
                    />
                  </div>
                  <div className="col-1">{menuItem.courseId}</div>
                  <div className="col-2">{menuItem.courseName}</div>
                  <div className="col-2">{menuItem.courseDescription}</div>
                  <div className="col-1">{menuItem.courseCategory}</div>
                  <div className="col-2">{menuItem.courseLevel}</div>
                  <div className="col-1">
                    <button className="btn btn-success">
                      <i
                        className="bi bi-pencil-fill"
                        onClick={() =>
                          navigate("/course/addcourse/" + menuItem.courseId)
                        }
                      ></i>
                    </button>
                    <button
                      className="btn btn-danger mx-2"
                      onClick={() => handleMenuItemDelete(menuItem.courseId)}
                    >
                      <i className="bi bi-trash-fill"></i>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default withAdminAuth(CourseList);
