import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { withAuth } from "../../../HOC";
const QuizViewStat = (props: any) => {
  const getCorrectIncorrectStatus = (ques: any) => {
    let _currAnswerOptions = ques.answerOptions;
    let correctAnswerIds = _currAnswerOptions
      .filter((x: any) => x.isCorrect)
      .map((a: any) => a.answerId);
    let userAnswersIds = props.userAnswersList
      .filter((x: any) => x.courseQuestionId === ques.courseQuestionId)
      .map((a: any) => a.userAnswerOptions);
    if (
      JSON.stringify(correctAnswerIds) === JSON.stringify(userAnswersIds[0])
    ) {
      return true;
    }
    return false;
  };
  const getQuizScore = (initailval: number, _correctCount: number) => {
    let result = initailval;
    props.questions.map((a: any) => {
      let _currAnswerOptions = a.answerOptions;
      let correctAnswerIds = _currAnswerOptions
        .filter((x: any) => x.isCorrect)
        .map((a: any) => a.answerId);
      let userAnswersIds = props.userAnswersList
        .filter((x: any) => x.courseQuestionId === a.courseQuestionId)
        .map((a: any) => a.userAnswerOptions);
      if (
        JSON.stringify(correctAnswerIds) === JSON.stringify(userAnswersIds[0])
      ) {
        _correctCount++;
      }
      result = Math.round((_correctCount / props.questions.length) * 100);
    });
    return result;
  };
  const getOverAllStatus = () => {
    let result = getQuizScore(0, 0);
    return (
      <React.Fragment>
        <React.Fragment>
          <p className="d-flex align-items-center justify-content-center m-0">
            {result < 100 ? <AiOutlineCloseCircle /> : <AiOutlineCheckCircle />}
            <span className="ms-1">
              <strong>Your score is {`${result}%`}</strong>
            </span>
            <span
              className={
                result == 100 ? "badge bg-success ms-3" : "badge bg-danger ms-3"
              }
            >
              {`${
                result == 100
                  ? "Congratulation!! You have Passed."
                  : "Sorry!! Your score is low. Please try again."
              }`}
              {/* {result == 100
                ? "Congratulation!! You have Passed."
                : "Sorry!! Your score is low. Please try again."} */}
            </span>
          </p>
        </React.Fragment>
      </React.Fragment>
    );
  };
  const handleQuizStatusClick = () => {
    let result = getQuizScore(0, 0);

    let newData = { quizScore: result, currContent: props.currContent };
    if (props.onBack) {
      props.onBack();
    } else {
      if (result < 100) {
        props.onQuizRetake && props.onQuizRetake(props.currUserQuizAttemptId);
      } else {
        props.onQuizPass && props.onQuizPass(newData);
      }
    }
  };
  const getButtonText = () => {
    let result = getQuizScore(0, 0);

    return (
      <Button
        className={`${
          (props.onBack && props.onBack) || result < 100
            ? "btn-secondary"
            : "btn-success"
        }`}
        onClick={() => {
          handleQuizStatusClick();
        }}
      >
        {props.onBack && props.onBack
          ? "Go to user quiz list"
          : result < 100
          ? "Re-take test"
          : "Completed"}{" "}
      </Button>
    );

    // let text = "";
    // if (result < 100) {
    //   text = "Re-take test";
    // }
    // // else if (props.isLastContent == true && props.isContentComplete == true) {
    // //   text = "Passed. Completed.";
    // // }
    // //  else if (props.isContentComplete == true) {
    // //   text = "Passed. Let's continue.";
    // // }
    // // else if (props.isLastContent == true) {
    // //   text = "Passed. Complete.";
    // // }
    // // else {
    // //   text = "Passed. Complete and Continue";
    // // }
    // return text;
  };
  return (
    <React.Fragment>
      {getOverAllStatus()}
      <hr />
      <div className="quiz-result">
        {props.questions.map((a: any) => {
          let _currQuestion = a.question;
          let _currAnswerOptions = a.answerOptions;
          return (
            <React.Fragment>
              <div className="mb-4 bg-light px-2 py-4">
                {getCorrectIncorrectStatus(a) ? (
                  <span className="badge bg-success">Correct</span>
                ) : (
                  <span className="badge bg-danger">Incorrect</span>
                )}

                <p className="quiz-question my-2">
                  <span
                    dangerouslySetInnerHTML={{ __html: _currQuestion }}
                    className="me-2"
                  ></span>
                </p>

                <p>
                  {_currAnswerOptions.map((b: any) => {
                    return (
                      <p className="mb-1">
                        {getCorrectIncorrectStatus(a) && b.isCorrect ? (
                          <React.Fragment>
                            <AiOutlineCheckCircle className="quiz-result-answer-correct" />
                            <strong className="text-success ms-1">
                              {b.answer}
                            </strong>
                             
                          </React.Fragment>
                        ) : (
                          <span style={{ paddingLeft: "18px" }}>
                            {b.answer}
                          </span>
                        )}
                      </p>
                    );
                  })}
                </p>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div className="pt-3 border-top border-secondary-subtle d-flex justify-content-end">
        {getButtonText()}
        {/* <Button
          onClick={() => {
            handleQuizStatusClick();
          }}
          themeColor={"secondary"}
        >
        
        </Button> */}
      </div>
    </React.Fragment>
  );
};

export default withAuth(QuizViewStat);
