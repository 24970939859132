import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { blogModel, courseModel } from "../../../Interfaces";
import { apiBaseUrl } from "../../../Constants/apiUrl";
import moment from "moment";
interface Props {
  menuItem: blogModel;
}

const BlogCardItem = (props: Props) => {
  const onImageError = (event: any) => {
    event.target.src = `${apiBaseUrl}/BlogImages/defaultCourse.png`;
  };
  const addedOn = moment(props.menuItem.addedOn).format("YYYY-MM-DD");
  const navigate = useNavigate();
  const redirectHandler = (courseId: number) => {
    navigate(`/blogdetails/${courseId}`);
  };
  // const handleMouseEvent = (e: React.MouseEvent<HTMLImageElement>) => {
  //   e.preventDefault();
  //   return false;
  // };
  return (
    <div className="col-md-3 col-12 my-3">
      <div className="course-card">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => redirectHandler(props.menuItem.blogId)}
        >
          <img
            // onContextMenu={handleMouseEvent}
            src={`${apiBaseUrl}/BlogImages/${props.menuItem.blogImgUrl} `}
            alt={props.menuItem.blogName}
            className=" card-img-top"
            onError={(e) => onImageError(e)}
          />
        </span>
        <div className="card-body mt-3">
          {/* <h5 className="card-title">Card title</h5> */}
          <span
            onClick={() => redirectHandler(props.menuItem.blogId)}
            style={{
              textDecoration: "none",
              color: "green",
              cursor: "pointer",
            }}
          >
            <h3 className="card-title fs-5">{props.menuItem.blogName}</h3>
          </span>
          <p className="card-text">{props.menuItem.blogShortDesc}</p>
        </div>

        <div className="card-footer pb-2">
          <span
            onClick={() => redirectHandler(props.menuItem.blogId)}
            className="btn btn-sm btn-success"
            style={{ cursor: "pointer" }}
          >
            Show more
          </span>
          {/* <a href="#" className="card-link">
            Another link
          </a> */}
          {/* <hr className="text-muted" />
          <span>
            <span>
              <i className="fa-solid fa-calendar-days text-muted me-2"></i>
            
            </span>

            <span className="text-muted">{addedOn}</span>
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default BlogCardItem;
