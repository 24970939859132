import React, { useState } from "react";
import { inputHelper, toastNotify } from "../../Helper";
import { apiResponse, userModel } from "../../Interfaces";
import { useLoginUserMutation } from "../../Apis/authApi";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { setLoggedInUser } from "../../Store/Redux/userAuthSlice";

import { MetaTag, MiniLoader } from "../../Components/Page/Common";
import { Container, Row, Col, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
let loginImg = require("../../Assets/Images/login.png");
let logo = require("../../Assets/Images/Logo_Final_edit.png");
const Login = () => {
  const [error, setError] = useState("");
  const [loginUser] = useLoginUserMutation();
  const [loading, setLoading] = useState(false);
  const [useInput, setUserInput] = useState({
    userName: "",
    password: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tempData = inputHelper(e, useInput);
    setUserInput(tempData);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const response: apiResponse = await loginUser({
      userName: useInput.userName,
      password: useInput.password,
    });
    if (response.data) {
      const { token, profileImage } = response.data.result;

      const { email, id, fullName, role }: userModel = jwt_decode(token);
      localStorage.setItem("token", token);
      localStorage.setItem("pImage", profileImage);
      dispatch(setLoggedInUser({ email, id, fullName, role, profileImage }));
      navigate("/");
    } else if (response.error) {
      console.log(response.error.data.errorMessages[0]);
      //setError(response.error.data.errorMessages[0]);
      toastNotify(response.error.data.errorMessages[0], "error");
    }
    setLoading(false);
  };
  return (
    // <div className="container text-center">
    //   {loading && <MiniLoader />}
    //   <form onSubmit={handleSubmit} method="post">
    //     <h1 className="mt-5">Login</h1>
    //     <div className="mt-5">
    //       <div className="col-sm-6 offset-sm-3 col-xs-12 mt-4">
    //         <input
    //           type="text"
    //           className="form-control"
    //           placeholder="Enter Username"
    //           required
    //           name="userName"
    //           value={useInput.userName}
    //           onChange={handleUserInput}
    //         />
    //       </div>

    //       <div className="col-sm-6 offset-sm-3 col-xs-12 mt-4">
    //         <input
    //           type="password"
    //           className="form-control"
    //           placeholder="Enter Password"
    //           required
    //           name="password"
    //           value={useInput.password}
    //           onChange={handleUserInput}
    //         />
    //       </div>
    //     </div>

    //     <div className="mt-2">
    //       {error && <p className="text-danger">{error}</p>}
    //       <button
    //         type="submit"
    //         className="btn btn-success"
    //         style={{ width: "200px" }}
    //       >
    //         Login
    //       </button>
    //     </div>
    //   </form>
    // </div>
    <>
      <MetaTag showAdScript={false} title="Login - Learn & Code together" />

      <div className="login-container">
        <Container>
          <Row className="d-flex align-items-center row flex-column-reverse flex-md-row">
            <Col md="6" sm="12">
              <form onSubmit={handleSubmit} method="post">
                <div className="form-header">
                  <div>
                    <h4 className="text-success fs-2">Login</h4>
                    <p className="text-muted">
                      Join to TheCodeHub to easily explore courses and improve
                      your skills
                    </p>
                  </div>
                  {loading && <MiniLoader />}
                </div>
                <div className="d-flex flex-column gap-4 mt-4">
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email"
                      required
                      name="userName"
                      value={useInput.userName}
                      onChange={handleUserInput}
                    />
                  </div>

                  <div>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Password"
                      required
                      name="password"
                      value={useInput.password}
                      onChange={handleUserInput}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between my-4 gap-2">
                  {/* {error && <p className="text-danger">{error}</p>} */}
                  <Button
                    type="submit"
                    disabled={loading}
                    variant="success"
                    className="flex-grow-1"
                  >
                    Login
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => navigate("/")}
                    className="flex-grow-1"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
              <span className="text-left">
                <p>
                  Forgot your password?{" "}
                  <NavLink to="/forgotpassword">Click here</NavLink>
                </p>
              </span>
              <div className="d-flex justify-content-center flex-column gap-3">
                <span className="mx-auto">Or</span>
                <Button
                  onClick={() => navigate("/register")}
                  variant="outline-success"
                >
                  Register
                </Button>
              </div>
            </Col>
            <Col md="6" sm="12">
              <img src={loginImg} alt="loginImg" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;
