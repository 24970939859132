import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../Constants/apiUrl";
import { toastNotify } from "../Helper";
import { setLoggedInUser, emptyUserState } from "../Store/Redux/userAuthSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: `${apiBaseUrl}/api`,
  prepareHeaders: (headers: Headers, api) => {
    const token = localStorage.getItem("token");
    token && headers.append("Authorization", "Bearer " + token);
    headers.append("Content-Type", "application/json");
    if (api.endpoint == "registerUser") {
      headers.delete("Authorization");
    }
    if (api.endpoint == "loginUser") {
      headers.delete("Authorization");
    }
    if (api.endpoint == "updateProfile") {
      headers.delete("Content-Type");
    }
  },
});
const baseQueryWithInterceptor = async (
  args: any,
  api: any,
  extraOptions: any
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // Deal with unauthorised
    // useLogOut();
    toastNotify("Your session is expired", "error");
    const timer = setTimeout(() => {
      localStorage.removeItem("token");
      api.dispatch(setLoggedInUser({ ...emptyUserState }));
      window.location.href = "/login";
    }, 3000);
  }
  return result;
};

const authApi = createApi({
  reducerPath: "authApi",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: `${apiBaseUrl}/api`,
  // }),
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (userData) => ({
        url: "auth/register",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: userData,
      }),
    }),
    loginUser: builder.mutation({
      query: (userCred) => ({
        url: "auth/login",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: userCred,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (userCred) => ({
        url: "auth/forgotpassword",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: userCred,
      }),
    }),
    updateProfile: builder.mutation({
      query: ({ data, id }) => ({
        url: "auth/updateprofile",
        method: "post",
        body: data,
      }),
    }),
    changePassword: builder.mutation({
      query: (userCred) => ({
        url: "auth/changepassword",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: userCred,
      }),
    }),
    logOut: builder.mutation({
      query: () => ({
        url: "auth/logout",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useLogOutMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useUpdateProfileMutation,
} = authApi;

export default authApi;
