import React, { useState } from "react";
import { useDispatch } from "react-redux";

import "./banner.css";
import { setSearchItem } from "../../../Store/Redux/courseSlice";
function Banner() {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchItem(e.target.value));
    setValue(e.target.value);
  };

  return (
    <div className="custom-banner">
      <div
        className="m-auto d-flex align-items-center"
        style={{
          width: "400px",
          height: "50vh",
        }}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: "100%" }}
        >
          <div style={{ position: "relative", width: "300px" }}>
            <input
              type={"text"}
              className="form-control rounded-pill"
              style={{
                width: "100%",
              }}
              value={value}
              onChange={handleChange}
              placeholder="Search for course!!"
            />
            <span style={{ position: "absolute", top: "6px", right: "20px" }}>
              {/* <i className="bi bi-search"></i> */}
              <i className="fa-solid fa-magnifying-glass"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
