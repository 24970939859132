import { LogoImage, ScrollTopButton } from "../Page/Common";
import { NavLink } from "react-router-dom";

const Footer = () => {
  let currYear = new Date().getFullYear();

  return (
    // <div
    //   className="footer
    // fixed-bottom text-center p-3 bg-dark text-white
    // "
    // >
    //   &copy; Test Quiz App 2023. All Rights Reserved.
    // </div>

    // <div className="footer bg-dark text-light px-5">
    //   <div>
    //     <ul>
    //       <li>
    //         <NavLink to="/">Home</NavLink>
    //       </li>
    //       <li>
    //         <NavLink to="/aboutus">About</NavLink>
    //       </li>
    //       <li>
    //         <NavLink to="/interviewquestion">Intrerview Questions</NavLink>
    //       </li>
    //       <li>
    //         <NavLink to="/leaderboard">Leader Board</NavLink>
    //       </li>
    //     </ul>
    //     <div className="d-flex justify-content-start flex-column  mt-5 gap-2 footer-bottom">
    //       <h3 className="m-0">Code Hub</h3>
    //       <p className="m-0 copyright-text">
    //         &copy; Code Hub {currYear}. All Rights Reserved.
    //       </p>
    //     </div>
    //   </div>
    //   {/* <div className="scroll-to-top">
    //     <span role="button" onClick={scrollToTop}>
    //       <i className="fa-solid fa-angle-up"></i>
    //     </span>
    //   </div> */}
    //   <div className="scroll-to-top">
    //     <ScrollTopButton />
    //   </div>
    // </div>
    <div className="footer-new bg-dark text-light  w-100 py-5 flex-shrink-0">
      <ul>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/aboutus">About</NavLink>
        </li>
        <li>
          <NavLink to="/interviewquestion">Intrerview Questions</NavLink>
        </li>
        <li>
          <NavLink to="/leaderboard">Leader Board</NavLink>
        </li>

        <li>
          <NavLink to="/blogs">Blogs</NavLink>
        </li>
        <li>
          <NavLink to="/privacy-policy">Privacy Policy</NavLink>
        </li>
      </ul>
      <div className="d-flex justify-content-start flex-column  mt-5 gap-2 footer-bottom">
        {/* <img src={logo} style={{ width: "70px" }} className="m-1" /> */}
        <div className="d-flex gap-2">
          <LogoImage width="70px" clsName="m-1" />
          <div className="footer-social">
            <ul>
              <li>
                <a target="_" href="https://www.facebook.com/learnwithcodehub">
                  <i className="fab fa-facebook-f icon"></i>{" "}
                </a>
              </li>

              <li>
                <a target="_" href="https://www.youtube.com/@learnwithcodehub">
                  <i className="fab fa-youtube icon"></i>
                </a>
              </li>
              <li>
                <a target="_" href="https://www.facebook.com/learnwithcodehub">
                  <i className="fab fa-instagram icon"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <p className=" copyright-text">
          &copy; The Code Hub {currYear}. All Rights Reserved.
        </p>
      </div>

      {/* <div className="scroll-to-top">
        <span role="button" onClick={scrollToTop}>
          <i className="fa-solid fa-angle-up"></i>
        </span>
      </div> */}
      <div className="scroll-to-top">
        <ScrollTopButton />
      </div>
    </div>
  );
};

export default Footer;
