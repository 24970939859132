import React, { useState } from "react";

import { useGetUserCourseQuizByCourseIdQuery } from "../../../Apis/courseApi";

import { MainLoader } from "../../../Components/Page/Common";
import { userQuizModel } from "../../../Interfaces";
import { useNavigate } from "react-router";

import LearnerQuizViewStat from "./LearnerQuizViewStat";

import { Container, Table } from "react-bootstrap";
const CourseQuizList = (props: any) => {
  const { data, isLoading } = useGetUserCourseQuizByCourseIdQuery(
    props.crsResult.courseId
  );
  const navigate = useNavigate();
  const [quizId, setQuizId] = useState(0);
  const [quizTitle, setQuizTitle] = useState("");
  const handleMenuItemDelete = (id: number, title: string) => {
    setQuizId(id);
    setQuizTitle(title);
  };

  return (
    <>
      {quizId == 0 && (
        <>
          {isLoading && <MainLoader />}
          {!isLoading && (
            <Container>
              <div className="d-flex align-items-start align-items-md-cetner justify-content-start justify-content-md-between flex-column flex-md-row mt-5">
                <p className="text-success fs-2">User Quiz List</p>

                <button
                  className="btn btn-success"
                  onClick={() => props.onBack()}
                >
                  {/* <i className="fa-solid fa-arrow-left me-2"></i> */}
                  Back to detail
                </button>
              </div>
              <Table responsive="sm" className="quiz-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Level</th>

                    <th>Course Name</th>
                    <th>Total Attempt</th>
                    <th>Total Score</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.result?.map((menuItem: userQuizModel) => {
                    return (
                      <>
                        {" "}
                        <tr>
                          {/* <div className="col-1">{menuItem.courseQuizId}</div> */}
                          <td className="">{menuItem.quizTitle}</td>
                          <td className="">{menuItem.quizLevel}</td>
                          {/* <td className="col-1">{menuItem.courseId}</td> */}
                          <td className="">{menuItem.courseName}</td>
                          <td className="">{menuItem.attemptCount}</td>
                          <td className="">{menuItem.totalScorePercent}</td>
                          <td className="">
                            {menuItem.totalScorePercent < 100 ? (
                              <button
                                onClick={() =>
                                  navigate(
                                    "/quiz/" +
                                      menuItem.courseQuizId +
                                      "/view/" +
                                      props.crsResult.courseId
                                  )
                                }
                                title="Play quiz"
                                className="btn btn-primary"
                              >
                                <i className="fa-solid fa-play"></i>
                              </button>
                            ) : (
                              <button
                                title="View quiz"
                                className="btn btn-danger"
                                onClick={() =>
                                  handleMenuItemDelete(
                                    menuItem.courseQuizId,
                                    menuItem.quizTitle
                                  )
                                }
                              >
                                <i className="fa-solid fa-eye"></i>
                              </button>
                            )}
                          </td>
                        </tr>{" "}
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </Container>
          )}
        </>
      )}

      {quizId > 0 && (
        <LearnerQuizViewStat
          quizTitle={quizTitle}
          onBack={() => setQuizId(0)}
          quizId={quizId}
        />
      )}
    </>
  );
};

export default CourseQuizList;

{
  /* <Table responsive>
<thead>
  <tr>
    <th>#</th>
    {Array.from({ length: 12 }).map((_, index) => (
      <th key={index}>Table heading</th>
    ))}
  </tr>
</thead>
<tbody>
  <tr>
    <td>1</td>
    {Array.from({ length: 12 }).map((_, index) => (
      <td key={index}>Table cell {index}</td>
    ))}
  </tr>
  <tr>
    <td>2</td>
    {Array.from({ length: 12 }).map((_, index) => (
      <td key={index}>Table cell {index}</td>
    ))}
  </tr>
  <tr>
    <td>3</td>
    {Array.from({ length: 12 }).map((_, index) => (
      <td key={index}>Table cell {index}</td>
    ))}
  </tr>
</tbody>
</Table> */
}
