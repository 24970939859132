import React, { useState } from "react";

const ScrollTopButton = () => {
  const [visible, setVisible] = useState(false);
  const toggleVissible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVissible);
  return (
    <>
      <i
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
        className="fa fa-arrow-up"
        aria-hidden="true"
      ></i>
    </>
  );
};

export default ScrollTopButton;
