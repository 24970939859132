import React, { useEffect, useState } from "react";
import {
  useCreateCourseMutation,
  useGetCourseByIdQuery,
  useUpdateCourseMutation,
} from "../../../Apis/courseApi";
import { inputHelper, toastNotify } from "../../../Helper";
import { useNavigate, useParams } from "react-router-dom";
import { MainLoader } from "../../../Components/Page/Common";
import {
  SD_Categories,
  SD_CourseLevel,
  SD_Program,
  SD_School_Class,
} from "../../../Utility/SD";
import { apiBaseUrl } from "../../../Constants/apiUrl";
import { userModel } from "../../../Interfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/Redux/store";
import ReactQuill from "react-quill";
import { withAdminAuth } from "../../../HOC";
const Categories = [
  SD_Categories["C#"],
  SD_Categories.React,
  SD_Categories.SQL,
  SD_Categories.QA,
];
const Programs = [
  SD_Program.School,
  // SD_Program.College,
  SD_Program.InformationTechnology,
];
const ClassTypes: Array<SD_School_Class> = [
  SD_School_Class.Class1,
  SD_School_Class.Class2,
  SD_School_Class.Class3,
  SD_School_Class.Class4,
  SD_School_Class.Class5,
];
const Levels = [
  SD_CourseLevel.Beginner,
  SD_CourseLevel.Intermediate,
  SD_CourseLevel.Advanced,
];
const menuItemData = {
  courseName: "",
  courseDescription: "",
  courseLevel: Levels[0],
  courseCategory: Categories[0],
  programType: Programs[1],
  classType: ClassTypes[0],
};

function AddCourse() {
  const { id } = useParams();

  const navigate = useNavigate();
  const [imageToStore, setImageToStore] = useState<any>();
  const [imageToDisplay, setImageToDisplay] = useState<string>("");
  const [menuItemInputs, setMenuItemInputs] = useState(menuItemData);
  const [loading, setLoading] = useState(false);
  const [showClass, setShowClass] = useState(false);
  const [createMenuItem] = useCreateCourseMutation();
  const [updateMenuItem] = useUpdateCourseMutation();
  const [questionContent, setQuestionContent] = useState<string>("");
  const { data } = useGetCourseByIdQuery(id);
  const handleContentChange = (data: any) => {
    setQuestionContent(data.toString());
  };
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  useEffect(() => {
    if (data && data.result) {
      const tempData = {
        courseName: data.result.courseName,
        courseDescription: data.result.courseDescription,
        courseLevel: data.result.courseLevel,
        courseCategory: data.result.courseCategory,
        programType: data.result.programType,
        classType: data.result.classType,
      };

      setMenuItemInputs(tempData);
      setQuestionContent(data.result.longCourseDescription);
      setImageToDisplay(data.result.courseImage);
    }
  }, [data]);

  const handleMenuItemInput = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (e.target.value == "School") {
      setShowClass(true);
    } else {
      setShowClass(false);
    }
    const tempData = inputHelper(e, menuItemInputs);
    setMenuItemInputs(tempData);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const imgType = file.type.split("/")[1];
      const validImgTypes = ["jpeg", "jpg", "png"];

      const isImageTypeValid = validImgTypes.filter((e) => {
        return e === imgType;
      });

      if (file.size > 1000 * 1024) {
        setImageToStore("");
        toastNotify("File Must be less then 1 MB", "error");
        return;
      } else if (isImageTypeValid.length === 0) {
        setImageToStore("");
        toastNotify("File Must be in jpeg, jpg or png", "error");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      setImageToStore(file);
      reader.onload = (e) => {
        const imgUrl = e.target?.result as string;

        setImageToDisplay(imgUrl);
      };
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (!imageToStore && !id) {
      toastNotify("Please upload an image", "error");
      setLoading(false);
      return;
    }

    const formData = new FormData();

    formData.append("CourseName", menuItemInputs.courseName);
    formData.append("CourseDescription", menuItemInputs.courseDescription);
    formData.append("LongCourseDescription", questionContent);
    formData.append(
      "CourseCategory",
      !showClass ? menuItemInputs.courseCategory : ""
    );
    formData.append("CourseLevel", menuItemInputs.courseLevel);
    formData.append("UserId", userData.id);
    formData.append("ProgramType", menuItemInputs.programType);
    formData.append("ClassType", showClass ? menuItemInputs.classType : "");
    if (imageToDisplay) formData.append("File", imageToStore);

    let response;

    if (id) {
      //update
      formData.append("CourseId", id);
      response = await updateMenuItem({ data: formData, id });
      toastNotify("Menu Item updated successfully", "success");
    } else {
      //create
      response = await createMenuItem(formData);
      toastNotify("Menu Item created successfully", "success");
    }

    if (response) {
      setLoading(false);
      navigate("/course/courselist");
    }

    setLoading(false);
  };
  const getBlogImg = () => {
    let imgUrl = "";
    if (imageToDisplay.includes("base64")) {
      imgUrl = imageToDisplay;
    } else {
      imgUrl = apiBaseUrl + "/BlogImages/" + imageToDisplay;
    }
    return imgUrl;
  };
  return (
    <div className="container border mt-5 p-5 bg-light">
      {loading && <MainLoader />}
      <h3 className=" px-2 text-success">
        {id ? "Edit Course" : "Add Course"}
      </h3>

      <form method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
        <div className="row mt-3">
          <div className="col-md-7">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Name"
              required
              name="courseName"
              value={menuItemInputs.courseName}
              onChange={handleMenuItemInput}
            />
            <textarea
              className="form-control mt-3"
              placeholder="Enter Description"
              name="courseDescription"
              rows={10}
              value={menuItemInputs.courseDescription}
              onChange={handleMenuItemInput}
            ></textarea>
            <ReactQuill
              className="editor mt-3"
              theme="snow"
              value={questionContent}
              id="questionContent"
              onChange={(e) => {
                handleContentChange(e);
              }}
            />
            <select
              className="form-control mt-3 form-select"
              name="courseLevel"
              value={menuItemInputs.courseLevel}
              onChange={handleMenuItemInput}
            >
              {Levels.map((level) => (
                <option value={level}>{level}</option>
              ))}
            </select>

            <select
              className="form-control mt-3 form-select"
              name="programType"
              value={menuItemInputs.programType}
              onChange={handleMenuItemInput}
            >
              {Programs.map((level) => (
                <option value={level}>{level}</option>
              ))}
            </select>
            {showClass && (
              <select
                className="form-control mt-3 form-select"
                name="classType"
                value={menuItemInputs.classType}
                onChange={handleMenuItemInput}
              >
                {ClassTypes.map((level) => (
                  <option value={level}>{level}</option>
                ))}
              </select>
            )}
            {!showClass && (
              <select
                className="form-control mt-3 form-select"
                name="courseCategory"
                value={menuItemInputs.courseCategory}
                onChange={handleMenuItemInput}
              >
                {Categories.map((category) => (
                  <option value={category}>{category}</option>
                ))}
              </select>
            )}

            <input
              type="file"
              onChange={handleFileChange}
              className="form-control mt-3"
            />
            <div className="row">
              <div className="col-6">
                <button
                  type="submit"
                  className="btn btn-success form-control mt-3"
                >
                  {id ? "Update" : "Create"}
                </button>
              </div>
              <div className="col-6">
                <a
                  onClick={() => navigate("/course/courselist")}
                  className="btn btn-secondary form-control mt-3"
                >
                  Back to courses
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-5 text-center">
            <img
              src={getBlogImg()}
              style={{ width: "100%", borderRadius: "30px" }}
              alt="courseImg"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default withAdminAuth(AddCourse);
