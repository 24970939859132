import React, { useEffect, useState } from "react";
import {
  useGetCourseByIdQuery,
  useUpdateCourseMutation,
} from "../../../Apis/courseApi";
import { inputHelper, toastNotify } from "../../../Helper";
import { useNavigate, useParams } from "react-router-dom";
import { MainLoader } from "../../../Components/Page/Common";
import { SD_Categories, SD_CourseLevel } from "../../../Utility/SD";

import { userModel } from "../../../Interfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/Redux/store";
import ReactQuill from "react-quill";
import {
  useCreateInterviewQuestionMutation,
  useGetInterviewContentByIdQuery,
  useUpdateInterViewContentMutation,
} from "../../../Apis/interviewApi";
import { withAdminAuth } from "../../../HOC";
const Categories = [
  SD_Categories["C#"],
  SD_Categories.React,
  SD_Categories.SQL,
  SD_Categories.QA,
];
const Levels = [
  SD_CourseLevel.Beginner,
  SD_CourseLevel.Intermediate,
  SD_CourseLevel.Advanced,
];
const menuItemData = {
  interviewQuestionContent: "",

  courseCategory: Categories[0],
};

function AddInterview() {
  const { id } = useParams();
  const [questionContent, setQuestionContent] = useState("");
  const navigate = useNavigate();
  const [imageToStore, setImageToStore] = useState<any>();
  const [imageToDisplay, setImageToDisplay] = useState<string>("");
  const [menuItemInputs, setMenuItemInputs] = useState(menuItemData);
  const [loading, setLoading] = useState(false);
  const [createMenuItem] = useCreateInterviewQuestionMutation();
  const [updateMenuItem] = useUpdateInterViewContentMutation();
  const { data } = useGetInterviewContentByIdQuery(id);
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  useEffect(() => {
    if (data && data.result) {
      const tempData = {
        interviewQuestionContent: data.result[0].interviewContent,

        courseCategory: data.result[0].category,
      };
      setMenuItemInputs(tempData);
      setQuestionContent(data.result[0].interviewContent);
    }
  }, [data]);

  const handleMenuItemInput = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const tempData = inputHelper(e, menuItemInputs);
    setMenuItemInputs(tempData);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    let postData = {
      Category: menuItemInputs.courseCategory,
      InterviewContent: questionContent,
      InterviewQuestionId: id,
    };

    let response;

    if (id) {
      // update

      response = await updateMenuItem(postData);
      toastNotify("Interview Question updated successfully", "success");
    } else {
      //create
      response = await createMenuItem(postData);
      toastNotify("Interview Question created successfully", "success");
    }

    if (response) {
      setLoading(false);
      navigate("/interviewquestionlist");
    }

    setLoading(false);
  };
  const handleContentChange = (data: any) => {
    setQuestionContent(data.toString());
  };
  return (
    <div className="container border mt-5 p-5 bg-light">
      {loading && <MainLoader />}
      <h3 className=" px-2 text-success">
        {id ? "Edit Course" : "Add Interview Question"}
      </h3>

      <form method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
        <div className="row mt-3">
          <div className="col-md-7">
            <ReactQuill
              className="editor"
              theme="snow"
              value={questionContent}
              id="questionContent"
              onChange={(e) => {
                handleContentChange(e);
              }}
            />

            <select
              className="form-control mt-3 form-select"
              name="courseCategory"
              value={menuItemInputs.courseCategory}
              onChange={handleMenuItemInput}
            >
              {Categories.map((category) => (
                <option value={category}>{category}</option>
              ))}
            </select>

            <div className="row">
              <div className="col-6">
                <button
                  type="submit"
                  className="btn btn-success form-control mt-3"
                >
                  {id ? "Update" : "Create"}
                </button>
              </div>
              <div className="col-6">
                <a
                  onClick={() => navigate("/interviewquestionlist")}
                  className="btn btn-secondary form-control mt-3"
                >
                  Back to interview questions
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default withAdminAuth(AddInterview);
