import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  AdScriptMetaTag,
  MetaTag,
  TypeWriter,
} from "../Components/Page/Common";
import { userModel } from "../Interfaces";

import { useSelector } from "react-redux";
import { RootState } from "../Store/Redux/store";
import { apiBaseUrl } from "../Constants";

//User ID c3f546a5-e560-4504-8521-ea4c6bc2d0a5
let perImg = require("../Assets/Images/sujeeb.jpg");

const AboutUs = () => {
  const navigate = useNavigate();

  const [showTypeWriter, setShowTypeWriter] = useState(true);

  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  useEffect(() => {
    //Implementing the setInterval method
    // const interval = setInterval(() => {
    //   setShowTypeWriter(!showTypeWriter);
    // }, 4000);
    // //Clearing the interval
    // return () => clearInterval(interval);
  }, [showTypeWriter]);
  // if (isLoading) {
  //   return (
  //     <div style={{ width: "100%" }} className="d-flex justify-content-center">
  //       <MainLoader />
  //     </div>
  //   );
  // }
  // if (isError) {
  //   return <div>Something went wrong!!</div>;
  // }
  const onImageError = (event: any) => {
    event.target.src = `${apiBaseUrl}/CourseImages/defaultCourse.png`;
  };

  return (
    <>
      <>
        <MetaTag
          src=""
          showAdScript={0}
          title="About us - Learn & Code together"
        />

        <div className="container pt-4 pt-md-5 course-detail">
          <div className="row d-flex flex-column-reverse flex-md-row">
            <div className="col-12 col-md-8">
              {/* <h2 className="text-success">- About Code Hub -</h2> */}
              <p className="text-success my-3 fs-2">- About 'TheCodeHub' -</p>
              {/* <span>
            <span
              className="badge text-bg-light pt-2"
              style={{ height: "40px", fontSize: "20px" }}
            >
              {data.result?.courseLevel}
            </span>
          </span> */}
              {/* <p style={{ fontSize: "20px" }} className="pt-2">
                  {data.result?.courseDescription}
                </p> */}
              {/* <style
                dangerouslySetInnerHTML={{
                  __html: `
     @keyframes ${word} {
      0% {
        width: 0;
      }
     
      40% {
        width: ${width + 10}px;
      }
      60% {
        width: ${width + 10}px;
      }
     
      100% {
        width: 0px;
      }
    }
    `,
                }}
              />
              <h1 className="cd-headline clip is-full-width">
                <span>My favourite food is</span>
                <span>&nbsp;</span>
                <span
                  className="cd-words-wrapper"
                  style={{ animation: `${word} 2s linear infinite` }}
                >
                  <b className="is-visible" ref={refCb}>
                    {word}
                  </b>
                </span>
              </h1> */}
              <p style={{ fontSize: "16px" }} className="pt-2 fw-bold">
                Hello there!!
                <TypeWriter
                  text=" Welcome to 'TheCodeHub'."
                  delay={150}
                  infinite
                />
                {/* {showTypeWriter && (
                  <TypeWriter
                    text="Hello there!! Welcome to Code Hub."
                    delay={100}
                  />
                )} */}
              </p>
              <p>
                Start learning adventure with us! Our platform is a hub of
                knowledge, offering a diverse range of online courses that cater
                to your interests and ambitions. Dive into interactive learning
                experiences, where quizzes transform education into a thrilling
                challenge.
              </p>
              <p>
                At the heart of our mission is your academic and personal
                growth. Join us in the pursuit of knowledge, and let's make
                learning an exhilarating journey together.
              </p>
              {/* <span className="h3">${data.result?.price}</span> &nbsp;&nbsp;&nbsp;
          <span
            className="pb-2  p-3"
            style={{ border: "1px solid #333", borderRadius: "30px" }}
          >
            <i
              className="bi bi-dash p-1"
              style={{ fontSize: "25px", cursor: "pointer" }}
            ></i>
            <span className="h3 mt-3 px-3">{3}</span>
            <i
              className="bi bi-plus p-1"
              style={{ fontSize: "25px", cursor: "pointer" }}
            ></i>
          </span> */}
            </div>
            <div className="col-12 col-md-4 mb-5">
              <div className="course-detail-sidebar">
                <img
                  src={`${perImg} `}
                  // style={{ borderRadius: "50%" }}
                  alt="about me"
                  onError={(e) => onImageError(e)}
                ></img>

                <p className="fw-bold text-success mt-2"> Sujeeb Palikhel</p>

                <p>Sr. SOFTWARE ENGINEER</p>
              </div>
              {/* <div className="social-icons">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-facebook"></i>
                </a>

                <a href="#" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default AboutUs;
