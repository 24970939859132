import React, { useState } from "react";

import { useGetUserLeaderBoardQuery } from "../Apis/courseApi";

import { MainLoader, MetaTag } from "../Components/Page/Common";
import { userLeaderBoardModel, userQuizModel } from "../Interfaces";
import { useNavigate } from "react-router";
import moment from "moment";

import { Container, Table } from "react-bootstrap";
const LeaderBoard = (props: any) => {
  const { data, isLoading } = useGetUserLeaderBoardQuery(null);
  const navigate = useNavigate();
  const [quizId, setQuizId] = useState(0);
  const [quizTitle, setQuizTitle] = useState("");

  return (
    <>
      <>
        <MetaTag
          title="LeaderBoard - Learn & Code together"
          showAdScript={true}
        />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6150950597865422"
          crossOrigin="anonymous"
        ></script>

        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-6150950597865422"
          data-ad-slot="3188949664"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        {isLoading && <MainLoader />}
        {!isLoading && (
          <Container>
            <div className="d-flex align-items-start align-items-md-cetner justify-content-start justify-content-md-between flex-column flex-md-row mt-5">
              <p className="text-success fs-2">Leader Board</p>
            </div>
            <Table
              striped
              bordered
              hover
              responsive="sm"
              className="quiz-table "
            >
              <thead>
                <tr>
                  <th>S.N</th>
                  <th>Name</th>
                  <th>Course</th>
                  <th>Quiz</th>
                  <th>Level</th>

                  <th>Total Attempt</th>
                  <th>Total Score</th>
                  <th>Attempted On</th>
                </tr>
              </thead>
              <tbody>
                {data.result?.map(
                  (menuItem: userLeaderBoardModel, index: number) => {
                    const date = moment(menuItem.attemptedDate).format(
                      "YYYY-MM-DD"
                    );

                    return (
                      <>
                        <tr>
                          <td className="">{index + 1}</td>
                          {/* <div className="col-1">{menuItem.courseQuizId}</div> */}
                          <td
                            // style={{
                            //   color: `${
                            //     menuItem.totalScorePercent == 100 &&
                            //     (index == 0 || index == 1 || index == 2)
                            //       ? "#198754"
                            //       : ""
                            //   }`,
                            // }}
                            className=""
                          >
                            {menuItem.name}
                          </td>
                          <td className="">{menuItem.courseName}</td>
                          <td className="">{menuItem.quizTitle}</td>
                          <td className="">{menuItem.quizLevel}</td>
                          {/* <td className="col-1">{menuItem.courseId}</td> */}

                          <td className="">{menuItem.attemptCount}</td>
                          <td className="">{menuItem.totalScorePercent}</td>
                          <td className="">{date}</td>
                        </tr>{" "}
                      </>
                    );
                  }
                )}
              </tbody>
            </Table>
          </Container>
        )}
      </>
    </>
  );
};

export default LeaderBoard;

{
  /* <Table responsive>
<thead>
  <tr>
    <th>#</th>
    {Array.from({ length: 12 }).map((_, index) => (
      <th key={index}>Table heading</th>
    ))}
  </tr>
</thead>
<tbody>
  <tr>
    <td>1</td>
    {Array.from({ length: 12 }).map((_, index) => (
      <td key={index}>Table cell {index}</td>
    ))}
  </tr>
  <tr>
    <td>2</td>
    {Array.from({ length: 12 }).map((_, index) => (
      <td key={index}>Table cell {index}</td>
    ))}
  </tr>
  <tr>
    <td>3</td>
    {Array.from({ length: 12 }).map((_, index) => (
      <td key={index}>Table cell {index}</td>
    ))}
  </tr>
</tbody>
</Table> */
}
