import React, { useEffect, useState } from "react";

const SiteMap = () => {
  const [sitemapContent, setSitemapContent] = useState<string | null>(null);
  useEffect(() => {
    // Fetch the sitemap XML file from the public directory
    fetch("/sitemap.xml")
      .then((response) => response.text())
      .then((data) => setSitemapContent(data))
      .catch((error) => console.error("Error fetching the sitemap:", error));
  }, []);
  return (
    <>
      {sitemapContent ? (
        <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          {sitemapContent}
        </pre>
      ) : (
        <p>Loading sitemap...</p>
      )}
    </>
  );
};

export default SiteMap;
