import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../Constants/apiUrl";
// https://localhost:5001
const interviewApi = createApi({
  reducerPath: "interviewApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiBaseUrl}/api`,
    prepareHeaders: (headers: Headers, api) => {
      const token = localStorage.getItem("token");
      token && headers.append("Authorization", "Bearer " + token);
      headers.append("Content-Type", "application/json");
    },
  }),
  tagTypes: ["interviews"],
  endpoints: (builder) => ({
    getInterviewContentById: builder.query({
      query: (id) => ({
        url: `interview/${id}`,
      }),
      providesTags: ["interviews"],
    }),
    getInterviewQuestionByCategory: builder.query({
      query: (cat) => ({
        url: `interview/GetInterviewQuestionByCategory/${cat}`,
      }),
      providesTags: ["interviews"],
    }),
    createInterviewQuestion: builder.mutation({
      query: (data) => ({
        url: "interview",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["interviews"],
    }),
    getInterviewContentList: builder.query<
      any,
      { offset: number; limit: number; programType: string }
    >({
      query: (arg) => {
        const { offset, limit, programType } = arg;
        console.log("arg: ", arg);
        return {
          url: "interview/GetInterviewList",
          params: { offset, limit, programType },
        };
      },
      providesTags: ["interviews"],
    }),
    updateInterViewContent: builder.mutation({
      query: (data) => ({
        url: "interview/UpdateInterviewQuestion",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["interviews"],
    }),
    deleteInterviewQuestion: builder.mutation({
      query: ({ data, id }) => ({
        url: "interview/" + id,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["interviews"],
    }),

    createCourseQuestion: builder.mutation({
      query: (data) => ({
        url: "course/CreateCourseQuestion",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["interviews"],
    }),
    getCourseQuestionByCourseId: builder.query({
      query: (id) => ({
        url: `course/GetCourseQuestion/${id}`,
      }),
      providesTags: ["interviews"],
    }),
  }),
});

export const {
  useGetInterviewContentByIdQuery,
  useGetInterviewQuestionByCategoryQuery,
  useCreateInterviewQuestionMutation,
  useUpdateInterViewContentMutation,
  useDeleteInterviewQuestionMutation,
  useCreateCourseQuestionMutation,
  useGetCourseQuestionByCourseIdQuery,
  useGetInterviewContentListQuery,
} = interviewApi;
export default interviewApi;
