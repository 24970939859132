import React from "react";
import { Link, Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const ClientLayout = () => {
  return (
    <>
      <Header />
      <div
        className="pb-5"
        style={{
          minHeight: "calc(100vh - 280px)",
          maxHeight: "max-content",
        }}
      >
        <Outlet />
      </div>

      <Footer />
    </>
  );
};

export default ClientLayout;
