import React, { useState } from "react";
import { Footer, Header } from "./Components/Layout";
import {
  AboutUs,
  AccessDenied,
  BlogDetails,
  Blogs,
  ChangePassword,
  Home,
  InformTech,
  InterViewQuestionPage,
  KidsLearning,
  LeaderBoard,
  NotFound,
  PrivacyPolicy,
  QuizPlayView,
  School,
  TechLearn,
  ViewProfile,
} from "./Pages";
import { Routes, Route, createBrowserRouter } from "react-router-dom";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import jwt_decode from "jwt-decode";
import { CourseList } from "./Pages/Admin/Course";
import AddCourse from "./Pages/Admin/Course/AddCourse";
import CourseDetails from "./Pages/CourseDetails";
import { ForgotPassword, Login, Register } from "./Pages/Account";
import { AddQuiz, QuizList } from "./Pages/Admin/Quiz";
import { setLoggedInUser } from "./Store/Redux/userAuthSlice";
import { userModel } from "./Interfaces";
import { RootState } from "./Store/Redux/store";
import { AddInterview, InterviewQuestionList } from "./Pages/Admin/Interview";
import Dashboard from "./Pages/Dashboard";
import { Helmet } from "react-helmet";
import AddBlog from "./Pages/Admin/Blog/AddBlog";
import BlogList from "./Pages/Admin/Blog/BlogList";
import AdminDashboard from "./Pages/Admin/AdminDashboard";
import AdminMain from "./Pages/Admin/AdminMain";
import ClientLayout from "./Components/Layout/ClientLayout";
import AdminLayout from "./Components/Layout/AdminLayout";
import SiteMap from "./Pages/SiteMap";
// rafce
function App() {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(true);
  const userData = useSelector((state: RootState) => state.userAuthStore);

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    const profileImage = localStorage.getItem("pImage");
    const _fullName = localStorage.getItem("pFullName");

    if (localToken) {
      const { fullName, id, email, role }: userModel = jwt_decode(localToken);
      dispatch(
        setLoggedInUser({
          fullName: _fullName ? _fullName : fullName,
          id,
          email,
          role,
          profileImage,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (userData.id) setSkip(false);
  }, [userData]);
  // let dashPath = window.location.pathname.includes("dashbaord");

  return (
    <div>
      <>
        <Routes>
          <Route path="/" element={<ClientLayout />}>
            <Route index element={<Home />} />
            <Route path="/course/courselist" element={<CourseList />} />
            <Route path="/blog/bloglist" element={<BlogList />} />

            <Route path="/kidslearn" element={<KidsLearning />} />
            <Route path="/sitemap" element={<SiteMap />} />
            <Route path="/techlearn" element={<TechLearn />} />
            <Route path="/school" element={<School />} />
            <Route path="/informtech" element={<InformTech />} />
            <Route path="/course/addcourse/:id" element={<AddCourse />} />
            <Route path="/course/addcourse" element={<AddCourse />} />
            <Route path="/course/quizlist" element={<QuizList />} />
            <Route path="/course/addquiz/:id" element={<AddQuiz />} />
            <Route path="/course/addquiz" element={<AddQuiz />} />
            <Route path="/blog/addblog/:id" element={<AddBlog />} />
            <Route path="/blog/addblog" element={<AddBlog />} />
            <Route
              path="/interviewquestion"
              element={<InterViewQuestionPage />}
            />
            <Route
              path="/coursedetails/:menuItemId"
              element={<CourseDetails />}
            ></Route>
            <Route
              path="/blogdetails/:menuItemId"
              element={<BlogDetails />}
            ></Route>
            <Route
              path="/quiz/:quizId/view/:courseId"
              element={<QuizPlayView />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

            <Route
              path="/interviewquestionlist"
              element={<InterviewQuestionList />}
            />
            <Route path="/leaderboard" element={<LeaderBoard />} />
            <Route path="/addinterview/:id" element={<AddInterview />} />
            <Route path="/addinterview" element={<AddInterview />} />
            <Route path="aboutus" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/profile" element={<ViewProfile />} />
            <Route path="/blogs" element={<Blogs />} />
            {/* <Route path="/admin/dashboard" element={<AdminDashboard />} /> */}
            <Route path="/accessdenied" element={<AccessDenied />} />
            <Route path="*" element={<NotFound />}></Route>
          </Route>
          {/* Admin Routes */}
          <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="courses" element={<AdminMain />} />
          </Route>
        </Routes>{" "}
        {/* <Routes>
          <Route path="/admin/dashboard" element={<AdminLayout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="dashboard" element={<AdminDashboard />} />
          </Route>
        </Routes> */}
        {/* {!window.location.pathname.includes("admin") && <Header />} */}
        {/* <div
          className="pb-5"
          style={{
            minHeight: "calc(100vh - 280px)",
            maxHeight: "max-content",
          }}
        >
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/course/courselist" element={<CourseList />} />
            <Route path="/blog/bloglist" element={<BlogList />} />
          
            <Route path="/school" element={<School />} />
            <Route path="/informtech" element={<InformTech />} />
            <Route path="/course/addcourse/:id" element={<AddCourse />} />
            <Route path="/course/addcourse" element={<AddCourse />} />
            <Route path="/course/quizlist" element={<QuizList />} />
            <Route path="/course/addquiz/:id" element={<AddQuiz />} />
            <Route path="/course/addquiz" element={<AddQuiz />} />
            <Route path="/blog/addblog/:id" element={<AddBlog />} />
            <Route path="/blog/addblog" element={<AddBlog />} />
            <Route
              path="/interviewquestion"
              element={<InterViewQuestionPage />}
            />
            <Route
              path="/coursedetails/:menuItemId"
              element={<CourseDetails />}
            ></Route>
            <Route
              path="/blogdetails/:menuItemId"
              element={<BlogDetails />}
            ></Route>
            <Route
              path="/quiz/:quizId/view/:courseId"
              element={<QuizPlayView />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

            <Route
              path="/interviewquestionlist"
              element={<InterviewQuestionList />}
            />
            <Route path="/leaderboard" element={<LeaderBoard />} />
            <Route path="/addinterview/:id" element={<AddInterview />} />
            <Route path="/addinterview" element={<AddInterview />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/profile" element={<ViewProfile />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/accessdenied" element={<AccessDenied />} />
            <Route path="*" element={<NotFound />}></Route>
           
          </Routes>
        </div>
        {!window.location.pathname.includes("admin") && <Footer />} */}
      </>
    </div>
  );
}

export default App;
