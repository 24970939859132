import React from "react";
import {
  useDeleteCourseMutation,
  useGetCoursesQuery,
} from "../../../Apis/courseApi";
import { toast } from "react-toastify";
import { MainLoader } from "../../../Components/Page/Common";
import {
  courseModel,
  interviewContentModel,
  userModel,
} from "../../../Interfaces";
import { useNavigate } from "react-router";
import { apiBaseUrl } from "../../../Constants/apiUrl";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/Redux/store";
import { withAdminAuth } from "../../../HOC";
import {
  useDeleteInterviewQuestionMutation,
  useGetInterviewContentListQuery,
} from "../../../Apis/interviewApi";

function InterviewQuestionList() {
  const [deleteMenuItem] = useDeleteInterviewQuestionMutation();
  const { data, isLoading } = useGetInterviewContentListQuery({
    offset: 1,
    limit: 1000,
    programType: "",
  });
  const navigate = useNavigate();
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  const handleMenuItemDelete = async (id: number) => {
    let postdata = {
      UserId: userData.id,
    };
    toast.promise(
      deleteMenuItem({ data: postdata, id }),
      {
        pending: "Processing your request...",
        success: "Interview question Deleted Successfully 👌",
        error: "Error encoutnered 🤯",
      },
      {
        theme: "dark",
      }
    );
  };

  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="table p-5">
          <div className="d-flex align-items-center justify-content-between">
            <h1 className="text-success">Interview Question List</h1>

            <button
              className="btn btn-success"
              onClick={() => navigate("/addinterview")}
            >
              Add New Interview Question
            </button>
          </div>

          <div className="p-2">
            <div className="row border">
              <div className="col-1">ID</div>

              <div className="col-1">Category</div>
              <div className="col-9">Content</div>
              <div className="col-1">Action</div>
            </div>

            {data.result.map((menuItem: interviewContentModel) => {
              return (
                <div className="row border" key={menuItem.interviewQuestionId}>
                  <div className="col-1">{menuItem.interviewQuestionId}</div>
                  <div className="col-1">{menuItem.category}</div>
                  <div className="col-9">{menuItem.interviewContent}</div>

                  <div className="col-1">
                    <button className="btn btn-success">
                      <i
                        className="bi bi-pencil-fill"
                        onClick={() =>
                          navigate(
                            "/addinterview/" + menuItem.interviewQuestionId
                          )
                        }
                      ></i>
                    </button>
                    <button
                      className="btn btn-danger mx-2"
                      onClick={() =>
                        handleMenuItemDelete(menuItem.interviewQuestionId)
                      }
                    >
                      <i className="bi bi-trash-fill"></i>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default withAdminAuth(InterviewQuestionList);
