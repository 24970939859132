import React from "react";

const LogoImage = (props: any) => {
  const { height, clsName, width } = props;
  let logo = require("../../../Assets/Images/Logo_Final_edit.png");
  return (
    <>
      <img
        src={logo}
        style={{ height: height, width: width }}
        className={clsName}
        alt="thecodehublogo"
      />
    </>
  );
};

export default LogoImage;
