import React from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

const Header = () => {
  let userImg = require("../../../Assets/Images/user.png");
  return (
    <>
      <Sidebar />
      <div className="main-container">
        <div className="page-header">
          <div className="toggle-sidebar" id="toggle-sidebar">
            <i className="bi bi-list"></i>
          </div>

          <ol className="breadcrumb d-md-flex d-none">
            <li className="breadcrumb-item">
              <i className="bi bi-house"></i>
              <a href="index.html">Home1</a>
            </li>
            <li className="breadcrumb-item">Layouts</li>
            <li
              className="breadcrumb-item breadcrumb-active"
              aria-current="page"
            >
              Grid Layout
            </li>
          </ol>

          <div className="header-actions-container">
            <div className="search-container">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search anything"
                />
                <button className="btn" type="button">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>

            <a href="orders.html" className="leads d-none d-xl-flex">
              <div className="lead-details">
                You have <span className="count"> 21 </span> new leads{" "}
              </div>
              <span className="lead-icon">
                <i className="bi bi-bell-fill animate__animated animate__swing animate__infinite infinite"></i>
                <b className="dot animate__animated animate__heartBeat animate__infinite"></b>
              </span>
            </a>

            <ul className="header-actions">
              <li className="dropdown">
                <a
                  href="#"
                  id="userSettings"
                  className="user-settings"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                >
                  <span className="user-name d-none d-md-block">
                    Abigale Heaney
                  </span>
                  <span className="avatar">
                    <img src={userImg} alt="Admin Templates" />
                    <span className="status online"></span>
                  </span>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="userSettings"
                >
                  <div className="header-profile-actions">
                    <a href="profile.html">Profile</a>
                    <a href="account-settings.html">Settings</a>
                    <a href="login.html">Logout</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <main>
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default Header;
