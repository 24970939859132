import React from "react";
import ReactPlayer from "react-player";
import { MetaTag } from "../Components/Page/Common";

const TechLearn = () => {
  return (
    <>
      {" "}
      <MetaTag
        showAdScript={false}
        title="Tech Learning - Learn & Code together"
      />
      <div className="container pt-4 pt-md-5 blog-post">
        <div className="row d-flex flex-column flex-md-row">
          <div className="col-12 col-lg-9 blog-post-content">
            <ReactPlayer
              className=" card-img-top"
              url="https://youtube.com/playlist?list=PLglCoGfdiO-1wY3FqoaW5qklolJGa40Ks&si=g9pLCVOyb9hI0t4Z"
              controls
              // light={defaultImgUrl}
            />
            <h1 className="title text-success mt-3">Learn 2D Shapes</h1>
          </div>
          <div className="col-12 col-lg-3 mb-5">
            <h4 className="text-success sub-title">Related Videos</h4>

            <div className="related-blog-sidebar">
              <div className="col-12 col-md-6 col-lg-auto related-blog-sidebar-card">
                <div className="course-card mb-3">
                  <span style={{ cursor: "pointer" }}>
                    {/* <img
                src={defaultImgUrl}
                alt="kidslearn"
                className=" react-player"
              /> */}
                    <ReactPlayer
                      className="react-player"
                      url="https://youtube.com/playlist?list=PLglCoGfdiO-1wY3FqoaW5qklolJGa40Ks&si=g9pLCVOyb9hI0t4Z"
                      controls
                      // light={defaultImgUrl}
                    />
                  </span>
                  <div className="card-body mt-3">
                    {/* <h5 className="card-title">Card title</h5> */}
                    <span
                      style={{
                        textDecoration: "none",
                        color: "green",
                        cursor: "pointer",
                      }}
                    >
                      Learn 2D Shapes
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-auto related-blog-sidebar-card">
                <div className="course-card mb-3">
                  <span style={{ cursor: "pointer" }}>
                    {/* <img
                src={defaultImgUrl}
                alt="kidslearn"
                className=" react-player"
              /> */}
                    <ReactPlayer
                      className="react-player"
                      url="https://youtube.com/playlist?list=PLglCoGfdiO-1wY3FqoaW5qklolJGa40Ks&si=g9pLCVOyb9hI0t4Z"
                      controls
                      // light={defaultImgUrl}
                    />
                  </span>
                  <div className="card-body mt-3">
                    {/* <h5 className="card-title">Card title</h5> */}
                    <span
                      style={{
                        textDecoration: "none",
                        color: "green",
                        cursor: "pointer",
                      }}
                    >
                      Learn 2D Shapes
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechLearn;
