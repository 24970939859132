import React from "react";
import Navbar from "../../Components/Layout/Admin/Navbar";

const AdminMain = (props: any) => {
  return (
    <>
      <div className="content-wrapper-scroll">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-sm-12 col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Courses</div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table v-middle">
                      <thead>
                        <tr>
                          <th>Customer</th>
                          <th>Product</th>
                          <th>User ID</th>
                          <th>Ordered Placed</th>
                          <th>Amount</th>
                          <th>Payment Status</th>
                          <th>Order Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="media-box">
                              <img
                                src="assets/images/user5.png"
                                className="media-avatar"
                                alt="Bootstrap Gallery"
                              />
                              <div className="media-box-body">
                                <div className="text-truncate">Sybil Boyd</div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="media-box">
                              <img
                                src="assets/images/food/img1.jpg"
                                className="media-avatar"
                                alt="Admin Themes"
                              />
                              <div className="media-box-body">
                                <div className="text-truncate">Spaghetti</div>
                              </div>
                            </div>
                          </td>
                          <td>Arise00435</td>
                          <td>2020/09/21</td>
                          <td>$46.00</td>
                          <td>
                            <span className="text-red">
                              <i className="bi bi-x-circle-fill"></i> Failed
                            </span>
                          </td>
                          <td>
                            <span className="badge shade-red min-90">
                              Cancelled
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="media-box">
                              <img
                                src="assets/images/user2.png"
                                className="media-avatar"
                                alt="Bootstrap Gallery"
                              />
                              <div className="media-box-body">
                                <div className="text-truncate">
                                  Lindsay Rogers
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="media-box">
                              <img
                                src="assets/images/food/img2.jpg"
                                className="media-avatar"
                                alt="Admin Themes"
                              />
                              <div className="media-box-body">
                                <div className="text-truncate">
                                  Garlic Kebabs
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>Arise00878</td>
                          <td>2020/10/25</td>
                          <td>$87.00</td>
                          <td>
                            <span className="text-blue">
                              <i className="bi bi-clock-fill"></i> Awaiting
                            </span>
                          </td>
                          <td>
                            <span className="badge shade-blue min-90">
                              Processing
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="media-box">
                              <img
                                src="assets/images/user3.png"
                                className="media-avatar"
                                alt="Bootstrap Gallery"
                              />
                              <div className="media-box-body">
                                <div className="text-truncate">
                                  Jewel Steele
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="media-box">
                              <img
                                src="assets/images/food/img3.jpg"
                                className="media-avatar"
                                alt="Admin Themes"
                              />
                              <div className="media-box-body">
                                <div className="text-truncate">
                                  Ginger Snacks
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>Arise00370</td>
                          <td>2020/10/30</td>
                          <td>$65.00</td>
                          <td>
                            <span className="text-green">
                              <i className="bi bi-check-circle-fill"></i> Paid
                            </span>
                          </td>
                          <td>
                            <span className="badge shade-green min-90">
                              Delivered
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="media-box">
                              <img
                                src="assets/images/user2.png"
                                className="media-avatar"
                                alt="Bootstrap Gallery"
                              />
                              <div className="media-box-body">
                                <div className="text-truncate">Zea Hansen</div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="media-box">
                              <img
                                src="assets/images/food/img4.jpg"
                                className="media-avatar"
                                alt="Admin Themes"
                              />
                              <div className="media-box-body">
                                <div className="text-truncate">
                                  Guava Sorbet
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>Arise00435</td>
                          <td>2020/10/12</td>
                          <td>$88.00</td>
                          <td>
                            <span className="text-red">
                              <i className="bi bi-x-circle-fill"></i> Failed
                            </span>
                          </td>
                          <td>
                            <span className="badge shade-red min-90">
                              Cancelled
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="media-box">
                              <img
                                src="assets/images/user2.png"
                                className="media-avatar"
                                alt="Bootstrap Gallery"
                              />
                              <div className="media-box-body">
                                <div className="text-truncate">
                                  Angelica King
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="media-box">
                              <img
                                src="assets/images/food/img5.jpg"
                                className="media-avatar"
                                alt="Admin Themes"
                              />
                              <div className="media-box-body">
                                <div className="text-truncate">
                                  Gooseberry Surprise
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>Arise00878</td>
                          <td>2020/10/11</td>
                          <td>$87.00</td>
                          <td>
                            <span className="text-blue">
                              <i className="bi bi-clock-fill"></i> Awaiting
                            </span>
                          </td>
                          <td>
                            <span className="badge shade-blue min-90">
                              Processing
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="media-box">
                              <img
                                src="assets/images/user.png"
                                className="media-avatar"
                                alt="Bootstrap Gallery"
                              />
                              <div className="media-box-body">
                                <div className="text-truncate">Nadia Hart</div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="media-box">
                              <img
                                src="assets/images/food/img2.jpg"
                                className="media-avatar"
                                alt="Admin Themes"
                              />
                              <div className="media-box-body">
                                <div className="text-truncate">
                                  Peanut Yogurt
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>Arise00435</td>
                          <td>2020/09/21</td>
                          <td>$46.00</td>
                          <td>
                            <span className="text-red">
                              <i className="bi bi-x-circle-fill"></i> Failed
                            </span>
                          </td>
                          <td>
                            <span className="badge shade-red min-90">
                              Cancelled
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="media-box">
                              <img
                                src="assets/images/user4.png"
                                className="media-avatar"
                                alt="Bootstrap Gallery"
                              />
                              <div className="media-box-body">
                                <div className="text-truncate">
                                  Tamara Aguilar
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="media-box">
                              <img
                                src="assets/images/food/img4.jpg"
                                className="media-avatar"
                                alt="Admin Themes"
                              />
                              <div className="media-box-body">
                                <div className="text-truncate">
                                  Almond Strudel
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>Arise00878</td>
                          <td>2020/10/25</td>
                          <td>$87.00</td>
                          <td>
                            <span className="text-blue">
                              <i className="bi bi-clock-fill"></i> Awaiting
                            </span>
                          </td>
                          <td>
                            <span className="badge shade-blue min-90">
                              Processing
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminMain;
