import React, { useEffect, useState } from "react";
import {
  useCreateCourseMutation,
  useGetCourseByIdQuery,
  useUpdateCourseMutation,
} from "../Apis/courseApi";

import { inputHelper, toastNotify } from "../Helper";
import { useNavigate, useParams } from "react-router-dom";
import { MainLoader, MetaTag } from "../Components/Page/Common";
import {
  SD_Categories,
  SD_CourseLevel,
  SD_Program,
  SD_School_Class,
} from "../Utility/SD";
import { apiBaseUrl } from "../Constants/apiUrl";
import { userModel, apiResponse } from "../Interfaces";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store/Redux/store";
import ReactQuill from "react-quill";
import { withAdminAuth, withAuth } from "../HOC";
import { useChangePasswordMutation, useLogOutMutation } from "../Apis/authApi";
import { setLoggedInUser, emptyUserState } from "../Store/Redux/userAuthSlice";
const Categories = [
  SD_Categories["C#"],
  SD_Categories.React,
  SD_Categories.SQL,
  SD_Categories.QA,
];
const Programs = [
  SD_Program.School,
  // SD_Program.College,
  SD_Program.InformationTechnology,
];
const ClassTypes: Array<SD_School_Class> = [
  SD_School_Class.Class1,
  SD_School_Class.Class2,
  SD_School_Class.Class3,
  SD_School_Class.Class4,
  SD_School_Class.Class5,
];
const Levels = [
  SD_CourseLevel.Beginner,
  SD_CourseLevel.Intermediate,
  SD_CourseLevel.Advanced,
];
const menuItemData = {
  courseName: "",
  courseDescription: "",
  courseLevel: Levels[0],
  courseCategory: Categories[0],
  programType: Programs[1],
  classType: ClassTypes[0],
};

function ChangePassword() {
  const { id } = useParams();
  const [useInput, setUserInput] = useState({
    userName: "",
    password: "",

    confirmPassword: "",
  });
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
    passWordLength: "",
  });
  const navigate = useNavigate();
  const [imageToStore, setImageToStore] = useState<any>();
  const [imageToDisplay, setImageToDisplay] = useState<string>("");
  const [menuItemInputs, setMenuItemInputs] = useState(menuItemData);
  const [loading, setLoading] = useState(false);
  const [showClass, setShowClass] = useState(false);
  const [createMenuItem] = useCreateCourseMutation();
  const [updateMenuItem] = useUpdateCourseMutation();
  const [chngPassword] = useChangePasswordMutation();
  const [questionContent, setQuestionContent] = useState<string>("");
  const { data } = useGetCourseByIdQuery(id);
  const dispatch = useDispatch();
  const [logOutUser] = useLogOutMutation();
  const handleContentChange = (data: any) => {
    setQuestionContent(data.toString());
  };
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  useEffect(() => {
    if (data && data.result) {
      const tempData = {
        courseName: data.result.courseName,
        courseDescription: data.result.courseDescription,
        courseLevel: data.result.courseLevel,
        courseCategory: data.result.courseCategory,
        programType: data.result.programType,
        classType: data.result.classType,
      };

      setMenuItemInputs(tempData);
      setQuestionContent(data.result.longCourseDescription);
      setImageToDisplay(data.result.courseImage);
    }
  }, [data]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (error.password != "" || error.confirmPassword != "") {
      setLoading(false);
      return;
    }
    if (useInput.password.length < 6) {
      toastNotify("Password must have 6 character length.", "error");
      setLoading(false);
      return;
    }

    const response: apiResponse = await chngPassword({
      userName: userData.email,
      password: useInput.password,
    });
    if (response.data) {
      toastNotify("Password changed successfully", "success");
      const timer = setTimeout(() => {
        logOutUser(null);
        localStorage.removeItem("token");
        dispatch(setLoggedInUser({ ...emptyUserState }));
        navigate("/");
        setLoading(false);
      }, 2000);
    } else if (response.error) {
      console.log(response.error.data.errorMessages[0]);
      //setError(response.error.data.errorMessages[0]);
      toastNotify(response.error.data.errorMessages[0], "error");
    }
    setLoading(false);
  };
  const handleUserInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const tempData = inputHelper(e, useInput);
    setUserInput(tempData);
    validateInput(e);
  };

  const validateInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (
            useInput.confirmPassword &&
            value !== useInput.confirmPassword
          ) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = useInput.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (useInput.password && value !== useInput.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };
  return (
    <>
      <MetaTag
        showAdScript={false}
        title="Change Password - Learn & Code together"
      />
      <div className="container border mt-5 p-5 bg-light">
        {loading && <MainLoader />}
        <h3 className=" px-2 text-success">Change Password</h3>

        <form
          method="post"
          encType="multipart/form-data"
          onSubmit={handleSubmit}
        >
          <div className="row mt-3">
            <div className="col-md-7">
              <input
                type="password"
                className="form-control"
                placeholder="Enter New Password"
                required
                name="password"
                value={useInput.password}
                onChange={handleUserInput}
              />
              {error.password && (
                <span className="text-danger">{error.password}</span>
              )}

              <input
                type="password"
                className="form-control mt-3"
                placeholder="Enter New Confirm Password"
                required
                name="confirmPassword"
                value={useInput.confirmPassword}
                onChange={handleUserInput}
              />
              {error.confirmPassword && (
                <span className="text-danger">{error.confirmPassword}</span>
              )}
              <div className="row">
                <div className="col-6">
                  <button
                    type="submit"
                    className="btn btn-success form-control mt-3"
                  >
                    Update
                  </button>
                </div>
                <div className="col-6">
                  <a
                    onClick={() => navigate("/")}
                    className="btn btn-secondary form-control mt-3"
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="col-md-5 text-center">
            <img
              src={`${apiBaseUrl}/CourseImages/${imageToDisplay} `}
              style={{ width: "100%", borderRadius: "30px" }}
              alt=""
            />
          </div> */}
          </div>
        </form>
      </div>
    </>
  );
}

export default withAuth(ChangePassword);
