import React from "react";

export const replaceSpecialCharacter = (codeString: string) => {
  codeString = codeString.replace(/'/g, "''");
  codeString = codeString.replace(/%/g, "%25");
  codeString = codeString.replace(/\+/g, "%2B");
  codeString = codeString.replace(/\//g, "%2F");
  codeString = codeString.replace(/\?/g, "%3F");
  codeString = codeString.replace(/#/g, "%23");
  codeString = codeString.replace(/&/g, "%26");
  return codeString;
};
