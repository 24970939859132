import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useGetCoursesQuery } from "../../../Apis/courseApi";
import { courseModel } from "../../../Interfaces";
import { setCourse } from "../../../Store/Redux/courseSlice";
import CourseCardItem from "./CourseCardItem";
import {
  SD_Categories,
  SD_CourseLevel,
  SD_Pagination,
  SD_Program,
  SD_SortTypes,
} from "../../../Utility/SD";
import { RootState } from "../../../Store/Redux/store";
import { MainLoader, PaginationPage } from "../Common";

const CourseList = () => {
  const Levels: Array<SD_CourseLevel> = [
    SD_CourseLevel.Beginner,
    SD_CourseLevel.Intermediate,
    SD_CourseLevel.Advanced,
  ];
  const Programs = [
    SD_Program.School,
    // SD_Program.College,
    SD_Program.InformationTechnology,
  ];
  const Categories: Array<SD_Categories> = [
    SD_Categories["C#"],
    SD_Categories.React,
    SD_Categories.SQL,
    SD_Categories.QA,
  ];
  const searchValue = useSelector(
    (state: RootState) => state.courseStore.search
  );
  const [courseItems, setCourseItems] = useState<courseModel[]>([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [categoryList, setCategoryList] = useState([""]);
  const [levelName, setLevelName] = useState(SD_CourseLevel.Beginner);
  const [currentPage, setCurrentPage] = useState(1);
  const pageNumberLimit = SD_Pagination.PageSize;
  const [maxPageLimit, setMaxPageLimit] = useState(SD_Pagination.PageSize);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [crsCat, setCrsCat] = useState("");
  const dispatch = useDispatch();

  const { data, isLoading, isFetching } = useGetCoursesQuery(
    {
      offset: currentPage,
      limit: pageNumberLimit,
      programType: crsCat != "All" ? crsCat : "",
    },
    { refetchOnMountOrArgChange: true }
  );
  // useEffect(() => {
  //   if (data && data.result) {
  //     const tempMenuArray = handleFilters(
  //       levelName,
  //       selectedCategory,
  //       searchValue
  //     );
  //     setCourseItems(tempMenuArray);
  //   }
  // }, [searchValue]);

  const handleSortClick = (i: number) => {
    setLevelName(Levels[i]);
    const tempArray = handleFilters(Levels[i], selectedCategory, searchValue);
    setCourseItems(tempArray);
  };
  const handleFilters = (
    level: SD_CourseLevel,
    category: string,
    search: string
  ) => {
    let tempArray =
      category === "All"
        ? [...data.result]
        : data.result.filter(
            (item: courseModel) =>
              item.programType.toUpperCase() === category.toUpperCase()
          );

    //search functionality
    if (search) {
      const tempArray2 = [...tempArray];
      tempArray = tempArray2.filter((item: courseModel) =>
        item.courseName.toUpperCase().includes(search.toUpperCase())
      );
    }
    // if (levelName === SD_CourseLevel.Beginner) {
    //   const tempArray2 = [...tempArray];
    //   tempArray = tempArray2.filter((item: courseModel) =>
    //     item.courseLevel
    //       .toUpperCase()
    //       .includes(SD_CourseLevel.Beginner.toUpperCase())
    //   );
    // }
    // if (levelName === SD_CourseLevel.Intermediate) {
    //   const tempArray2 = [...tempArray];
    //   tempArray = tempArray2.filter((item: courseModel) =>
    //     item.courseLevel
    //       .toUpperCase()
    //       .includes(SD_CourseLevel.Intermediate.toUpperCase())
    //   );
    // }
    // if (levelName === SD_CourseLevel.Advanced) {
    //   const tempArray2 = [...tempArray];
    //   tempArray = tempArray2.filter((item: courseModel) =>
    //     item.courseLevel
    //       .toUpperCase()
    //       .includes(SD_CourseLevel.Advanced.toUpperCase())
    //   );
    // }

    return tempArray;
  };
  useEffect(() => {
    if (!isLoading) {
      dispatch(setCourse(data.result));
      setCourseItems(data.result);
      const tempCategoryList = ["All"];
      Programs.forEach((item) => {
        if (tempCategoryList.indexOf(item) === -1) {
          tempCategoryList.push(item);
        }
      });

      setCategoryList(tempCategoryList);
    }
  }, [isLoading, currentPage, isFetching]);
  const handleCategoryClick = (i: number, cat: string) => {
    setCurrentPage(1);
    setCrsCat(cat);
    const buttons = document.querySelectorAll(".custom-buttons");
    let localCategory;
    buttons.forEach((button, index) => {
      if (index === i) {
        button.classList.add("active");
        if (index === 0) {
          localCategory = "All";
        } else {
          localCategory = categoryList[index];
        }
        setSelectedCategory(localCategory);
        // const tempArray = handleFilters(levelName, localCategory, searchValue);
        // setCourseItems(tempArray);
      } else {
        button.classList.remove("active");
      }
    });
  };
  // if (!isLoading) {
  //   return <MainLoader />;
  // }
  const paginationAttributes = {
    currentPage,
    maxPageLimit,
    minPageLimit,
    response: courseItems,
  };
  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const onPrevClick = () => {
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    setCurrentPage((prev) => prev - 1);
  };

  const onNextClick = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    setCurrentPage((prev) => prev + 1);
  };
  return (
    <>
      {/* <div className="row">
        <p className="text-success my-3 fs-2">- About 'TheCodeHub' -</p>
        <p>
          Start learning adventure with us! Our platform is a hub of knowledge,
          offering a diverse range of online courses that cater to your
          interests and ambitions. Dive into interactive learning experiences,
          where quizzes transform education into a thrilling challenge.
        </p>
        <p>
          At the heart of our mission is your academic and personal growth. Join
          us in the pursuit of knowledge, and let's make learning an
          exhilarating journey together.
        </p>
      </div> */}

      <div className="row">
        <p className="text-success my-3 fs-2">Courses</p>
      </div>

      <div className="my-2">
        <div>{isFetching && <MainLoader />}</div>
        {/* <p className="text-success fs-2">Latest Courses</p> */}
        <ul className="nav w-100 d-flex justify-content-start">
          {categoryList.map((categoryName, index) => (
            <li className="nav-item" key={index}>
              <button
                className={`nav-link p-0 pb-2 custom-buttons fs-5 ${
                  index === 0 && "active"
                } `}
                onClick={() => handleCategoryClick(index, categoryName)}
              >
                {categoryName}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="row">
        {courseItems.length > 0 && (
          <>
            {courseItems.map((menuItem: courseModel, index: number) => (
              <CourseCardItem menuItem={menuItem} key={index} />
            ))}
            <PaginationPage
              {...paginationAttributes}
              onPrevClick={onPrevClick}
              onNextClick={onNextClick}
              onPageChange={onPageChange}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CourseList;
