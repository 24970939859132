import React, { useState } from "react";
import { menuItems } from "./adminMenu";
import { Link, NavLink } from "react-router-dom";
import { LogoImage } from "../../Page/Common";
import { Helmet } from "react-helmet";

const Sidebar = () => {
  interface MenuItem {
    title: string;
    path: string;
    icon: string;
    subMenu?: MenuItem[];
  }

  interface SidebarProps {
    menuItems: MenuItem[];
  }
  const [openItems, setOpenItems] = useState<string[]>([]);

  const handleToggle = (title: string) => {
    setOpenItems((prevOpenItems) =>
      prevOpenItems.includes(title)
        ? prevOpenItems.filter((item) => item !== title)
        : [...prevOpenItems, title]
    );
  };
  const renderMenu = (items: MenuItem[], level = 0) => {
    return (
      <ul style={{ paddingLeft: level * 20 }}>
        {items.map((item) => (
          <li key={item.title}>
            {item.subMenu ? (
              <div
                onClick={() => handleToggle(item.title)}
                style={{ cursor: "pointer" }}
              >
                {openItems.includes(item.title) ? "▼" : "▶"} {item.title}
              </div>
            ) : (
              <Link to={item.path || "#"}>
                {" "}
                <i className={` ${item.icon}`}></i>
                {item.title}
              </Link>
            )}
            {item.subMenu &&
              openItems.includes(item.title) &&
              renderMenu(item.subMenu, level + 1)}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <>
      <Helmet>
        <link href="./../main.min.css" rel="stylesheet" />
        <script src="../../js/jquery.min.js"></script>
        <script src="../../js/bootstrap.bundle.min.js"></script>
        <script src="../../js/modernizr.js"></script>
        <script src="../../js/moment.js"></script>
        <script src="../../vendor/overlay-scroll/jquery.overlayScrollbars.min.js"></script>
        <script src="../../vendor/overlay-scroll/custom-scrollbar.js"></script>

        <script src="../../js/main.js"></script>
      </Helmet>

      <div id="loading-wrapper">
        <div className="spinner">
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
          <div className="line4"></div>
          <div className="line5"></div>
          <div className="line6"></div>
        </div>
      </div>
      <div className="page-wrapper">
        <nav className="sidebar-wrapper">
          <div className="sidebar-brand">
            <NavLink
              className="nav-link me-5"
              style={{ color: "#fff" }}
              aria-current="page"
              to="/"
            >
              <LogoImage height="60px" clsName="m-1" />
              {/* <img src={logo} style={{ height: "60px" }} className="m-1" /> */}
              {/* Code Hub */}
            </NavLink>
          </div>

          <div className="sidebar-menu">
            <div className="sidebarMenuScroll">
              <ul>
                <li>
                  {/* <a href="starter-page.html">
                    <i className="bi bi-hand-index-thumb"></i>
                    <span className="menu-text">Dashboard</span>
                  </a> */}

                  <NavLink className="menu-text" to="/admin/dashboard">
                    <i className="bi bi-hand-index-thumb"></i> Dashboard
                  </NavLink>
                </li>
                <li className="sidebar-dropdown">
                  <a href="#">
                    <i className="bi bi-house"></i>
                    <span className="menu-text">Course</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li>
                        <NavLink to="/admin/courses">Courses</NavLink>
                      </li>
                      <li>
                        <a href="reports.html">Reports</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="sidebar-dropdown">
                  <a href="#">
                    <i className="bi bi-handbag"></i>
                    <span className="menu-text">Product</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li>
                        <a href="orders.html">Order History</a>
                      </li>
                      <li>
                        <a href="products.html">Products</a>
                      </li>
                      <li>
                        <a href="view-cart.html">Shopping Cart</a>
                      </li>
                      <li>
                        <a href="checkout.html">Billing Details</a>
                      </li>
                      <li>
                        <a href="customers.html">Customers</a>
                      </li>
                      <li>
                        <a href="add-product.html">Add Product</a>
                      </li>
                      <li>
                        <a href="reviews.html">Reviews</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="sidebar-dropdown">
                  <a href="#">
                    <i className="bi bi-gem"></i>
                    <span className="menu-text">Widgets</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li>
                        <a href="widgets.html">Widgets</a>
                      </li>
                      <li>
                        <a href="graph-widgets.html">Graph Widgets</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="sidebar-dropdown">
                  <a href="#">
                    <i className="bi bi-collection"></i>
                    <span className="menu-text">UI Elements</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li>
                        <a href="accordions.html">Accordions</a>
                      </li>
                      <li>
                        <a href="alerts.html">Alerts</a>
                      </li>
                      <li>
                        <a href="buttons.html">Buttons</a>
                      </li>
                      <li>
                        <a href="badges.html">Badges</a>
                      </li>
                      <li>
                        <a href="cards.html">Cards</a>
                      </li>
                      <li>
                        <a href="carousel.html">Carousel</a>
                      </li>
                      <li>
                        <a href="dropdowns.html">Dropdowns</a>
                      </li>
                      <li>
                        <a href="icons.html">Icons</a>
                      </li>
                      <li>
                        <a href="modals.html">Modals</a>
                      </li>
                      <li>
                        <a href="offcanvas.html">Off Canvas</a>
                      </li>
                      <li>
                        <a href="progress.html">Progress Bars</a>
                      </li>
                      <li>
                        <a href="spinners.html">Spinners</a>
                      </li>
                      <li>
                        <a href="tabs.html">Tabs</a>
                      </li>
                      <li>
                        <a href="tooltips.html">Tooltips</a>
                      </li>
                      <li>
                        <a href="typography.html">Typography</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="sidebar-dropdown">
                  <a href="#">
                    <i className="bi bi-stickies"></i>
                    <span className="menu-text">Pages</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li>
                        <a href="profile.html">Profile</a>
                      </li>
                      <li>
                        <a href="account-settings.html">Account Settings</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href="calendar.html">
                    <i className="bi bi-calendar4"></i>
                    <span className="menu-text">Calendar</span>
                  </a>
                </li>
                <li className="sidebar-dropdown">
                  <a href="#">
                    <i className="bi bi-columns-gap"></i>
                    <span className="menu-text">Forms</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li>
                        <a href="form-inputs.html">Form Inputs</a>
                      </li>
                      <li>
                        <a href="form-checkbox-radio.html">
                          Checkbox &amp; Radio
                        </a>
                      </li>
                      <li>
                        <a href="form-file-input.html">File Input</a>
                      </li>
                      <li>
                        <a href="form-validations.html">Validations</a>
                      </li>
                      <li>
                        <a href="bs-select.html">Bootstrap Select</a>
                      </li>
                      <li>
                        <a href="date-time-pickers.html">Date Time Pickers</a>
                      </li>
                      <li>
                        <a href="input-mask.html">Input Masks</a>
                      </li>
                      <li>
                        <a href="editor.html">Editor</a>
                      </li>
                      <li>
                        <a href="form-layout1.html">Form Layout</a>
                      </li>
                      <li>
                        <a href="form-layout2.html">Form Layout 2</a>
                      </li>
                      <li>
                        <a href="form-layout3.html">Form Layout 3</a>
                      </li>
                      <li>
                        <a href="form-layout4.html">Form Horizontal</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="sidebar-dropdown">
                  <a href="#">
                    <i className="bi bi-window-split"></i>
                    <span className="menu-text">Tables</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li>
                        <a href="tables.html">Tables</a>
                      </li>
                      <li>
                        <a href="data-tables.html">Data Tables</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="sidebar-dropdown">
                  <a href="#">
                    <i className="bi bi-pie-chart"></i>
                    <span className="menu-text">Graphs &amp; Maps</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li>
                        <a href="apex.html">Apex</a>
                      </li>
                      <li>
                        <a href="morris.html">Morris</a>
                      </li>
                      <li>
                        <a href="maps.html">Maps</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="sidebar-dropdown active">
                  <a href="#">
                    <i className="bi bi-layout-sidebar"></i>
                    <span className="menu-text">Layouts</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li>
                        <a href="layout.html" className="current-page">
                          Default Layout
                        </a>
                      </li>
                      <li>
                        <a href="layout-grid.html">Grid Layout</a>
                      </li>
                      <li>
                        <a href="layout-welcome.html">Welcome Layout</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="sidebar-dropdown">
                  <a href="#">
                    <i className="bi bi-x-diamond"></i>
                    <span className="menu-text">Authentication</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li>
                        <a href="login.html">Login</a>
                      </li>
                      <li>
                        <a href="signup.html">Signup</a>
                      </li>
                      <li>
                        <a href="error.html">Error</a>
                      </li>
                      <li>
                        <a href="maintenance.html">Maintenance</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href="starter-page.html">
                    <i className="bi bi-hand-index-thumb"></i>
                    <span className="menu-text">Link</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
