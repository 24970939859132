import React from "react";
import { useGetInterviewQuestionByCategoryQuery } from "../../../Apis/interviewApi";
import { MainLoader } from "../Common";
import { replaceSpecialCharacter } from "../../../Helper/TextFormatter";

const InterviewDetail = (props: any) => {
  let _formattedText = replaceSpecialCharacter(props.cat);
  const { data, isLoading } =
    useGetInterviewQuestionByCategoryQuery(_formattedText);
  return (
    <>
      {" "}
      {isLoading && <MainLoader />}
      {!isLoading && (
        <>
          {" "}
          <h3 className="text-success">{props.cat}</h3>
          <hr />
          {data.result.map((a: any) => {
            return (
              <>
                {" "}
                <p
                  className="lead"
                  dangerouslySetInnerHTML={{
                    __html: a.interviewContent,
                  }}
                ></p>
              </>
            );
          })}
        </>
      )}
    </>
  );
};

export default InterviewDetail;
