import { NavLink, useNavigate } from "react-router-dom";
import { apiResponse, userModel } from "../../Interfaces";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Store/Redux/store";
import { apiBaseUrl } from "../../Constants/apiUrl";
import {
  emptyUserState,
  setLoggedInUser,
} from "../../Store/Redux/userAuthSlice";
import { SD_Program, SD_Roles } from "../../Utility/SD";
import { useLogOutMutation } from "../../Apis/authApi";
import { useState } from "react";
import { LogoImage, MainLoader } from "../Page/Common";

let logo = require("../../Assets/Images/logo1.png");

function Header() {
  const [loading, setLoading] = useState(false);
  const Programs = [
    SD_Program.School,
    // SD_Program.College,
    SD_Program.InformationTechnology,
  ];
  const [logOutUser] = useLogOutMutation();
  const dispatch = useDispatch();
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );

  const handleLogout = async () => {
    setLoading(true);
    const response: apiResponse = await logOutUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("pImage");
    localStorage.removeItem("pFullName");

    dispatch(setLoggedInUser({ ...emptyUserState }));
    navigate("/");
    setLoading(false);
  };
  const navigate = useNavigate();
  const onImageError = (event: any) => {
    event.target.src = `${apiBaseUrl}/ProfileImages/defaultUser.jpg`;
  };
  const getProfileImg = () => {
    let imgUrl = "";
    if (userData.profileImage?.includes("base64")) {
      imgUrl = userData.profileImage;
    } else {
      imgUrl = apiBaseUrl + "/ProfileImages/" + userData.profileImage;
    }
    return imgUrl;
  };
  return (
    <div>
      <nav className="navbar navbar-expand-lg primary-menu gap-2">
        <div className="container">
          <NavLink
            className="nav-link me-5"
            style={{ color: "#fff" }}
            aria-current="page"
            to="/"
          >
            <LogoImage height="60px" clsName="m-1" />
            {/* <img src={logo} style={{ height: "60px" }} className="m-1" /> */}
            {/* Code Hub */}
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="  navbar-nav ms-auto mb-2 mb-lg-0 w-100">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/aboutus">
                  About us
                </NavLink>
              </li>
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Programs
                </a>
                <ul className="dropdown-menu">
                  {Programs.map((pr) => {
                    let navUrl = pr == "School" ? "/school" : "/informtech";
                    return (
                      <>
                        <li
                          style={{ cursor: "pointer" }}
                          className="dropdown-item"
                          onClick={() => navigate(navUrl)}
                        >
                          {pr}
                        </li>
                      </>
                    );
                  })}
                </ul>
              </li> */}

              {/* {userData.role == SD_Roles.ADMIN ? (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Admin Panel
                  </a>
                  <ul className="dropdown-menu">
                    <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => navigate("menuItem/menuitemlist")}
                    >
                      Menu Item
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => navigate("order/myorders")}
                    >
                      My Orders
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => navigate("order/allOrders")}
                    >
                      All Orders
                    </li>
                  </ul>
                </li>
              ) : (
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="/order/myorders"
                  >
                    Orders
                  </NavLink>
                </li>
              )} */}

              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  aria-current="page"
                  to="/leaderboard"
                >
                  Leader Board
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  aria-current="page"
                  to="/interviewquestion"
                >
                  Interview Questions
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" to="/blogs">
                  Blogs
                </NavLink>
              </li>
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Learning
                </a>
                <ul className="dropdown-menu">
                  <li
                    style={{ cursor: "pointer" }}
                    className="dropdown-item"
                    onClick={() => navigate("kidslearn")}
                  >
                    Kids Learning
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    className="dropdown-item"
                    onClick={() => navigate("techlearn")}
                  >
                    Tech Learning
                  </li>
                </ul>
              </li> */}
              {userData.role == SD_Roles.ADMIN && (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Admin Panel
                  </a>
                  <ul className="dropdown-menu">
                    <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => navigate("course/courselist")}
                    >
                      Course
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => navigate("course/quizlist")}
                    >
                      Quiz
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => navigate("/interviewquestionlist")}
                    >
                      Interview Questions
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => navigate("/blog/bloglist")}
                    >
                      Blogs
                    </li>
                  </ul>
                </li>
              )}
              {userData.id && (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Profile
                  </a>
                  <ul className="dropdown-menu">
                    {userData.role == SD_Roles.ADMIN && (
                      <li
                        style={{ cursor: "pointer" }}
                        className="dropdown-item"
                        onClick={() => navigate("admin/dashboard")}
                      >
                        Admin Dashboard
                      </li>
                    )}
                    <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => navigate("/profile")}
                    >
                      View Profile
                    </li>
                    {/* <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => navigate("course/quizlist")}
                    >
                      My Score
                    </li> */}
                    <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => navigate("/changepassword")}
                    >
                      Change Password
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={handleLogout}
                    >
                      Log Out
                    </li>
                  </ul>
                </li>
              )}
              <div className="d-flex align-items-center justify-content-start justify-content-lg-end ms-lg-auto">
                {userData.id && (
                  <>
                    <li className="nav-item mx-2">
                      <img
                        onClick={() => navigate("/profile")}
                        onError={(e) => onImageError(e)}
                        src={getProfileImg()}
                        alt=""
                        style={{
                          height: "35px",
                          width: "35px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                      />
                    </li>

                    <li className="nav-item top-bar-user">
                      <button
                        onClick={() => navigate("/profile")}
                        className="nav-link active"
                        style={{
                          cursor: "pointer",
                          background: "transparent",
                          border: 0,
                        }}
                      >
                        Welcome, <br /> {userData.fullName}
                      </button>
                    </li>
                    <li className="nav-item top-menu-btn">
                      <button
                        className="btn btn-success btn-sm mx-2"
                        onClick={handleLogout}
                      >
                        <i className="fa-solid fa-right-to-bracket me-2"></i>
                        {/* <i className="bi bi-box-arrow-in-right me-2"></i> */}
                        Logout
                      </button>
                    </li>
                  </>
                )}

                {!userData.id && (
                  <>
                    <li className="nav-item text-white">
                      <NavLink className="nav-link" to="/register">
                        Register
                      </NavLink>
                    </li>
                    <li className="nav-item top-menu-btn">
                      <NavLink
                        className="btn btn-success btn-sm mx-2"
                        to="/login"
                      >
                        <i className="fa-solid fa-right-to-bracket me-2"></i>
                        {/* <i className="bi bi-box-arrow-in-right me-2"></i> */}
                        Login
                      </NavLink>
                    </li>{" "}
                  </>
                )}

                {/* {!userData.id && (
                  <>
                    <li className="nav-item text-white">
                      <NavLink className="nav-link" to="/register">
                        Register
                      </NavLink>
                    </li>
                    <li className="nav-item text-white">
                      <NavLink
                        className="btn btn-success btn-outlined rounded-pill text-white mx-2"
                        style={{
                          border: "none",
                          height: "40px",
                          width: "100px",
                        }}
                        to="/login"
                      >
                        Login
                      </NavLink>
                    </li>
                  </>
                )} */}
              </div>
            </ul>
          </div>
        </div>
      </nav>
      {loading && <MainLoader />}
    </div>
  );
}

export default Header;
