import { createSlice } from "@reduxjs/toolkit";

const initailState = {
  courses: [],
  search: "",
  courseId: 0,
};

export const courseSlice = createSlice({
  name: "Course",
  initialState: initailState,
  reducers: {
    setCourse: (state, action) => {
      state.courses = action.payload;
    },
    setSearchItem: (state, action) => {
      state.search = action.payload;
    },
    setCurrCourseId: (state, action) => {
      state.courseId = action.payload;
    },
  },
});

export const { setCourse, setSearchItem, setCurrCourseId } =
  courseSlice.actions;
export const courseReducer = courseSlice.reducer;
