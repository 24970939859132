import React from "react";
import { NavLink } from "react-router-dom";
import { MetaTag } from "../Components/Page/Common";

const NotFound = () => {
  return (
    <>
      <MetaTag showAdScript={false} title="Not Found - Learn & Code together" />

      <div className="container pt-4 pt-md-5 course-detail">
        <div className="row d-flex flex-column-reverse flex-md-row">
          <div className="col-12 col-md-8">
            {/* <h2 className="text-success">- About Code Hub -</h2> */}
            <p className="text-danger my-3 fs-2"> ERROR 404 OOPS !</p>
            {/* <span>
            <span
              className="badge text-bg-light pt-2"
              style={{ height: "40px", fontSize: "20px" }}
            >
              {data.result?.courseLevel}
            </span>
          </span> */}
            {/* <p style={{ fontSize: "20px" }} className="pt-2">
                  {data.result?.courseDescription}
                </p> */}
            {/* <style
                dangerouslySetInnerHTML={{
                  __html: `
     @keyframes ${word} {
      0% {
        width: 0;
      }
     
      40% {
        width: ${width + 10}px;
      }
      60% {
        width: ${width + 10}px;
      }
     
      100% {
        width: 0px;
      }
    }
    `,
                }}
              />
              <h1 className="cd-headline clip is-full-width">
                <span>My favourite food is</span>
                <span>&nbsp;</span>
                <span
                  className="cd-words-wrapper"
                  style={{ animation: `${word} 2s linear infinite` }}
                >
                  <b className="is-visible" ref={refCb}>
                    {word}
                  </b>
                </span>
              </h1> */}

            <p>We're Sorry. The page you are looking for doesn't exist.</p>
            <p>
              <NavLink to="/" className="btn btn-secondary">
                BACK TO HOME
              </NavLink>
            </p>
            {/* <span className="h3">${data.result?.price}</span> &nbsp;&nbsp;&nbsp;
          <span
            className="pb-2  p-3"
            style={{ border: "1px solid #333", borderRadius: "30px" }}
          >
            <i
              className="bi bi-dash p-1"
              style={{ fontSize: "25px", cursor: "pointer" }}
            ></i>
            <span className="h3 mt-3 px-3">{3}</span>
            <i
              className="bi bi-plus p-1"
              style={{ fontSize: "25px", cursor: "pointer" }}
            ></i>
          </span> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
