import "../index.css";
import "react-toastify/dist/ReactToastify.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../scss/custom-style.scss";

const ClientTheme = () => {
  return <></>;
};

export default ClientTheme;
