import React, { useState } from "react";

import { useGetUserLeaderBoardQuery } from "../Apis/courseApi";

import { MainLoader, MetaTag } from "../Components/Page/Common";
import { userLeaderBoardModel, userQuizModel } from "../Interfaces";
import { useNavigate } from "react-router";
import moment from "moment";

import { Container, Table } from "react-bootstrap";

import { SchoolCourseList } from "../Components/Page/School";
import { BlogList } from "../Components/Page/Blog";
const Blogs = (props: any) => {
  const { data, isLoading } = useGetUserLeaderBoardQuery(null);
  const navigate = useNavigate();
  const [quizId, setQuizId] = useState(0);
  const [quizTitle, setQuizTitle] = useState("");

  return (
    <>
      <>
        <MetaTag showAdScript={true} title="Blog - Learn & Code together" />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6150950597865422"
          crossOrigin="anonymous"
        ></script>

        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-6150950597865422"
          data-ad-slot="3188949664"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        {isLoading && <MainLoader />}
        {!isLoading && (
          <Container>
            <div className="d-flex align-items-start align-items-md-cetner justify-content-start justify-content-md-between flex-column flex-md-row mt-5">
              <p className="text-success fs-2">Blogs</p>
            </div>
            <div className="container p-2" style={{ position: "relative" }}>
              <BlogList />
            </div>
          </Container>
        )}
      </>
    </>
  );
};

export default Blogs;
