import { SD_QuizTypes } from "../Utility/SD";

export const getQuizQuestionTypes = () => {
  let types = [];

  for (var n in SD_QuizTypes) {
    if (typeof SD_QuizTypes[n] === "number") {
      types.push({ id: SD_QuizTypes[n], title: n });
    }
  }
  return types;
};

export default getQuizQuestionTypes;
