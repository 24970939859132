import React, { useState } from "react";

import { useGetUserLeaderBoardQuery } from "../Apis/courseApi";

import { MainLoader, MetaTag } from "../Components/Page/Common";
import { userLeaderBoardModel, userQuizModel } from "../Interfaces";
import { useNavigate } from "react-router";
import moment from "moment";

import { Container, Table } from "react-bootstrap";
import { InformTechCourseList } from "../Components/Page/InformTech";
const InformTech = (props: any) => {
  const { data, isLoading } = useGetUserLeaderBoardQuery(null);
  const navigate = useNavigate();
  const [quizId, setQuizId] = useState(0);
  const [quizTitle, setQuizTitle] = useState("");

  return (
    <>
      <>
        <MetaTag
          title="IT Program - Learn & Code together"
          showAdScript={true}
        />
        {isLoading && <MainLoader />}
        {!isLoading && (
          <Container>
            <div className="d-flex align-items-start align-items-md-cetner justify-content-start justify-content-md-between flex-column flex-md-row mt-5">
              <p className="text-success fs-2">IT Courses</p>
            </div>
            <div className="container p-2" style={{ position: "relative" }}>
              <InformTechCourseList />
            </div>
          </Container>
        )}
      </>
    </>
  );
};

export default InformTech;

{
  /* <Table responsive>
<thead>
  <tr>
    <th>#</th>
    {Array.from({ length: 12 }).map((_, index) => (
      <th key={index}>Table heading</th>
    ))}
  </tr>
</thead>
<tbody>
  <tr>
    <td>1</td>
    {Array.from({ length: 12 }).map((_, index) => (
      <td key={index}>Table cell {index}</td>
    ))}
  </tr>
  <tr>
    <td>2</td>
    {Array.from({ length: 12 }).map((_, index) => (
      <td key={index}>Table cell {index}</td>
    ))}
  </tr>
  <tr>
    <td>3</td>
    {Array.from({ length: 12 }).map((_, index) => (
      <td key={index}>Table cell {index}</td>
    ))}
  </tr>
</tbody>
</Table> */
}
