import React, { useState } from "react";
import { InternalSymbolName } from "typescript";
import { InterviewDetail } from "../Components/Page/InterviewPage";
import { SD_Categories } from "../Utility/SD";
import { MetaTag } from "../Components/Page/Common";

const InterViewQuestionPage = () => {
  const Categories = [
    SD_Categories["C#"],
    SD_Categories.React,
    SD_Categories.SQL,
    SD_Categories.QA,
  ];
  const [currCat, setCurrCat] = useState(SD_Categories["C#"]);
  return (
    <>
      <MetaTag title="Interview - Learn & Code together" showAdScript={true} />
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6150950597865422"
        crossOrigin="anonymous"
      ></script>

      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-6150950597865422"
        data-ad-slot="3188949664"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      <div className="table container">
        <div className="">
          <p className="text-success my-3 fs-2">Interview Questions</p>
        </div>

        <div className="">
          <div className="interview-question-page">
            <div className="row flex-nowrap p-2 gap-4">
              <div className="col-auto col-md-2 col-xl-2 px-sm-2 px-0 bg-success sidebar">
                <div className="d-flex flex-row flex-md-column align-items-center align-items-sm-start px-3 py-2 text-white">
                  <ul
                    className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
                    id="menu"
                  >
                    {Categories.map((cat, index) => {
                      return (
                        <>
                          <li
                            onClick={() => setCurrCat(cat)}
                            key={cat}
                            className="nav-item"
                          >
                            <span
                              style={{ cursor: "pointer" }}
                              className="nav-link align-middle px-0"
                            >
                              <span className="ms-1 d-sm-inline">{cat}</span>
                            </span>
                          </li>
                        </>
                      );
                    })}

                    {/* <li>
                    <a href="#" className="nav-link px-0 align-middle">
                      <span className="ms-1 d-none d-sm-inline">React</span>
                    </a>
                  </li>

                  <li>
                    <a href="#" className="nav-link px-0 align-middle">
                      <span className="ms-1 d-none d-sm-inline">SQL</span>{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#" className="nav-link px-0 align-middle">
                      <span className="ms-1 d-none d-sm-inline">QA</span>{" "}
                    </a>
                  </li> */}
                  </ul>
                  <hr />
                </div>
              </div>
              <div className="col py-3">
                <InterviewDetail cat={currCat} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterViewQuestionPage;
