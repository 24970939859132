export enum SD_Roles {
  ADMIN = "admin",
  CUSTOMER = "customer",
}
export enum SD_Status {
  PENDING = "Pending",
  CONFIRMED = "Confirmed",
  BEING_COOKED = "Being Cooked",
  READY_FOR_PICKUP = "Ready for Pickup",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
}

export enum SD_Categories {
  "C#" = "C#",
  React = "React",
  SQL = "SQL",
  QA = "QA",
}

export enum SD_CourseLevel {
  Beginner = "Beginner",
  Intermediate = "Intermediate",
  Advanced = "Advanced",
}
export enum SD_SortTypes {
  PRICE_LOW_HIGH = "Price Low - High",
  PRICE_HIGH_LOW = "Price High - Low",
  NAME_A_Z = "Name A - Z",
  NAME_Z_A = "Name Z - A",
}
export enum SD_QuizTypes {
  "Add Multiple Choice Question" = 1,
  "Add True/False Question" = 2,
  "GIFT Format Multiple Question" = 2,
}
export enum SD_Pagination {
  PageSize = 8,
}
export enum SD_Program {
  School = "School",
  // College = "College",
  InformationTechnology = "Information Technology",
}

export enum SD_School_Class {
  Class1 = "Class 1",
  Class2 = "Class 2",
  Class3 = "Class 3",
  Class4 = "Class 4",
  Class5 = "Class 5",
}
