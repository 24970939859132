import React from "react";
import { AdScriptMetaTag, Banner, MetaTag } from "../Components/Page/Common";
import { CourseList } from "../Components/Page/Home";
import { Helmet } from "react-helmet";
let logo = require("../Assets/Images/Logo_Final_edit.png");
const Home = () => {
  return (
    <div>
      <MetaTag
        title="TheCodeHub - Learn & Code together"
        showAdScript={1}
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6150950597865422"
      />
      {/* <AdScriptMetaTag /> */}
      <Banner />
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6150950597865422"
        crossOrigin="anonymous"
      ></script>

      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-6150950597865422"
        data-ad-slot="3188949664"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      <div className="container p-2" style={{ position: "relative" }}>
        <CourseList />
      </div>
    </div>
  );
};

export default Home;
