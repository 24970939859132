import React, { useEffect, useState } from "react";
import {
  useCreateCourseQuizMutation,
  useDeleteCourseQuestionMutation,
  useGetCourseQuestionByCourseIdQuery,
  useGetCourseQuizByIdQuery,
  useGetCoursesQuery,
  useUpdateCourseQuizMutation,
} from "../../../Apis/courseApi";
import { inputHelper, quizHelper, toastNotify } from "../../../Helper";
import { useNavigate, useParams } from "react-router-dom";
import { MainLoader } from "../../../Components/Page/Common";
import { SD_CourseLevel } from "../../../Utility/SD";

import { QuizQuestionPage } from "../../../Components/Page/CourseQuiz";
import { courseModel } from "../../../Interfaces";

import { Table } from "react-bootstrap";
import { setCurrCourseId } from "../../../Store/Redux/courseSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Store/Redux/store";
import { toast } from "react-toastify";
import { withAdminAuth } from "../../../HOC";
const Levels = [
  SD_CourseLevel.Beginner,
  SD_CourseLevel.Intermediate,
  SD_CourseLevel.Advanced,
];
const menuItemData = {
  quizTitle: "",
  quizType: 0,
  quizLevel: Levels[0],
  courseId: 0,
};

let QuizTypes = quizHelper();
function AddQuiz() {
  const { id } = useParams();
  const courseData = useSelector((state: RootState) => state.courseStore);

  const navigate = useNavigate();
  const [imageToStore, setImageToStore] = useState<any>();
  const [imageToDisplay, setImageToDisplay] = useState<string>("");
  const [menuItemInputs, setMenuItemInputs] = useState(menuItemData);
  const [loading, setLoading] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [createCourseQuiz] = useCreateCourseQuizMutation();
  const [updateCourseQuiz] = useUpdateCourseQuizMutation();
  const { data: crsQuizData } = useGetCourseQuizByIdQuery(id);
  const { data: crsQuestionData } = useGetCourseQuestionByCourseIdQuery(
    courseData.courseId,
    { skip: courseData.courseId == 0 }
  );
  const [deleteMenuItem] = useDeleteCourseQuestionMutation();
  const { data, isLoading } = useGetCoursesQuery({
    offset: 1,
    limit: 100,
    programType: "",
  });

  const [selectedQuestions, setSelectedQuestions] = useState<number[]>([]);

  const [showQuizQuestion, setShowQuizQuestion] = useState(false);
  const [currQuestion, setCurrQuestion] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (crsQuizData && crsQuizData.result) {
      const tempData = {
        quizTitle: crsQuizData.result[0].quizTitle,
        quizLevel: crsQuizData.result[0].quizLevel,
        quizType: 0,
        courseId: crsQuizData.result[0].courseId,
      };
      setMenuItemInputs(tempData);
      dispatch(setCurrCourseId(parseInt(crsQuizData.result[0].courseId)));
      setSelectedQuestions(crsQuizData.result[0].questionIds);
    }
  }, [crsQuizData]);
  useEffect(() => {
    if (crsQuestionData && crsQuestionData.result) {
      setQuestions(crsQuestionData.result);
    }
  }, [crsQuestionData]);

  const handleMenuItemInput = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const tempData = inputHelper(e, menuItemInputs);
    setMenuItemInputs(tempData);
    if (e.target.name == "quizType" && parseInt(e.target.value) > 0) {
      setShowQuizQuestion(true);
    }
    if (e.target.name == "courseId" && parseInt(e.target.value) > 0) {
      dispatch(setCurrCourseId(parseInt(e.target.value)));
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const imgType = file.type.split("/")[1];
      const validImgTypes = ["jpeg", "jpg", "png"];

      const isImageTypeValid = validImgTypes.filter((e) => {
        return e === imgType;
      });

      if (file.size > 1000 * 1024) {
        setImageToStore("");
        toastNotify("File Must be less then 1 MB", "error");
        return;
      } else if (isImageTypeValid.length === 0) {
        setImageToStore("");
        toastNotify("File Must be in jpeg, jpg or png", "error");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      setImageToStore(file);
      reader.onload = (e) => {
        const imgUrl = e.target?.result as string;
        setImageToDisplay(imgUrl);
      };
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    console.log(menuItemInputs);
    if (menuItemInputs.courseId == 0) {
      toastNotify(" Please select course", "error");
      setLoading(false);
      return;
    }
    if (questions.length == 0) {
      if (menuItemInputs.quizType == 0) {
        toastNotify(" Please select quiz type", "error");
        setLoading(false);
        return;
      }
    }

    if (selectedQuestions.length == 0) {
      toastNotify(
        " Please add question or select at least one question",
        "error"
      );
      setLoading(false);
      return;
    }
    let quizData = {
      CourseQuizId: id,
      QuizTitle: menuItemInputs.quizTitle,
      QuizLevel: menuItemInputs.quizLevel,
      CourseId: menuItemInputs.courseId,
      QuestionIds: selectedQuestions,
    };
    let postData = JSON.stringify(quizData);
    let response;
    if (id) {
      //update

      response = await updateCourseQuiz({ data: postData, id });
      toastNotify("Quiz updated successfully", "success");
    } else {
      //create
      response = await createCourseQuiz(postData);
      toastNotify("Quiz created successfully", "success");
    }

    if (response) {
      setLoading(false);
      navigate("/course/quizlist");
    }

    setLoading(false);
  };
  const onCancelHandler = () => {
    setShowQuizQuestion(false);
    const tempData = {
      quizTitle: "",
      quizType: 0,
      quizLevel: Levels[0],
      courseId: 0,
    };
    setMenuItemInputs(tempData);
  };
  const saveQuestionHandler = (data: any) => {
    setQuestions(data.questionData.data.result);
    const tempData = {
      quizTitle: data.quizData.quzTitle,
      quizType: data.quizData.quizType,
      quizLevel: data.quizData.quizLevel,
      courseId: data.quizData.courseId,
    };
    setMenuItemInputs(tempData);
    setShowQuizQuestion(false);
  };
  const QuestionUseHandler = (
    event: React.FormEvent<HTMLButtonElement>,
    questionid: number
  ) => {
    event.preventDefault();
    if (selectedQuestions.filter((x) => x === questionid).length > 0) {
      let newArray = selectedQuestions.filter((x) => x !== questionid);
      setSelectedQuestions(newArray);
      // const index = newArray.indexOf(data);
      // if (index > -1) {
      //   // only splice array when item is found
      //   newArray.splice(index, 1); // 2nd parameter means remove one item only

      //   let newFormState = newArray;
      //   setQuestionSaveData(newArray);
      // }
    } else {
      let newFormState = [...selectedQuestions, questionid];
      setSelectedQuestions(newFormState);
    }
  };
  const backToQuizList = () => {
    dispatch(setCurrCourseId(0));
    navigate("/course/quizlist");
  };
  const editQuestionHandler = (ques: any) => {
    setCurrQuestion(ques);
    setShowQuizQuestion(true);
  };
  const handleQuestionDelete = (
    e: React.FormEvent<HTMLButtonElement>,
    id: number
  ) => {
    e.preventDefault();
    toast.promise(
      deleteMenuItem(id),
      {
        pending: "Processing your request...",
        success: "Question Deleted Successfully 👌",
        error: "Error encoutnered 🤯",
      },
      {
        theme: "dark",
      }
    );
  };
  return (
    <>
      {showQuizQuestion && (
        <QuizQuestionPage
          quizVal={menuItemInputs}
          onCancel={onCancelHandler}
          onSaveQuestion={saveQuestionHandler}
          question={currQuestion}
        />
      )}
      {!showQuizQuestion && (
        <div className="container border mt-5 p-5 bg-light">
          {loading && <MainLoader />}
          <h3 className=" px-2 text-success">
            {id ? "Edit Quiz" : "Add Quiz"}
          </h3>

          <form
            method="post"
            encType="multipart/form-data"
            onSubmit={handleSubmit}
          >
            <div className="row mt-3">
              <div className="col-md-7">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Quiz Title"
                  required
                  name="quizTitle"
                  value={menuItemInputs.quizTitle}
                  onChange={handleMenuItemInput}
                />

                <select
                  className="form-control mt-3 form-select"
                  name="quizLevel"
                  value={menuItemInputs.quizLevel}
                  onChange={handleMenuItemInput}
                >
                  {Levels.map((qt) => (
                    <option value={qt}>{qt}</option>
                  ))}
                </select>
                <select
                  className="form-control mt-3 form-select"
                  name="courseId"
                  value={menuItemInputs.courseId}
                  onChange={handleMenuItemInput}
                >
                  <option value={0}>Select Course</option>
                  {data?.result.map((menuItem: courseModel) => (
                    <option value={menuItem.courseId}>
                      {menuItem.formattedCourse}
                    </option>
                  ))}
                </select>
                <select
                  className="form-control mt-3 form-select"
                  name="quizType"
                  value={menuItemInputs.quizType}
                  onChange={handleMenuItemInput}
                >
                  <option value={0}>Select Quiz Type</option>
                  {QuizTypes.map((qt) => (
                    <option value={qt.id}>{qt.title}</option>
                  ))}
                </select>
                <br />
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>In Use</th>
                      <th>Question</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {questions.map((ques: any) => {
                      return (
                        <>
                          <tr>
                            <td>
                              <button
                                onClick={(e) =>
                                  QuestionUseHandler(e, ques.courseQuestionId)
                                }
                                className={
                                  // {
                                  selectedQuestions.filter(
                                    (x) => x === ques.courseQuestionId
                                  ).length > 0
                                    ? "btn btn-success"
                                    : "btn btn-primary"
                                  // },
                                }
                              >
                                {selectedQuestions.filter(
                                  (x) => x === ques.courseQuestionId
                                ).length > 0
                                  ? "Added"
                                  : "Add"}
                              </button>
                            </td>
                            <td>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: ques.questionHtml,
                                }}
                              ></p>
                            </td>
                            <td>
                              {" "}
                              <button
                                className="btn btn-success"
                                onClick={() => editQuestionHandler(ques)}
                              >
                                <i className="bi bi-pencil-fill"></i>
                              </button>
                              <button
                                className="btn btn-danger mx-2"
                                onClick={(e) =>
                                  handleQuestionDelete(e, ques.courseQuestionId)
                                }
                              >
                                <i className="bi bi-trash-fill"></i>
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="row">
                  <div className="col-6">
                    <button
                      type="submit"
                      className="btn btn-success form-control mt-3"
                    >
                      {id ? "Update" : "Create"}
                    </button>
                  </div>
                  <div className="col-6">
                    <a
                      onClick={backToQuizList}
                      className="btn btn-secondary form-control mt-3"
                    >
                      Back to quiz lsit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default withAdminAuth(AddQuiz);
