import React from "react";
import { Helmet } from "react-helmet";
let logo = require("../../../Assets/Images/Logo_Final_edit.png");
const AdScriptMetaTag = (props: any) => {
  return (
    <>
      <Helmet>
        <script
          async
          src={
            "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6150950597865422"
          }
          crossOrigin="anonymous"
        ></script>
      </Helmet>
    </>
  );
};

export default AdScriptMetaTag;
