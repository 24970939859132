import React from "react";
import { Helmet } from "react-helmet";
let logo = require("../../../Assets/Images/Logo_Final_edit.png");
const MetaTag = (props: any) => {
  return (
    <>
      <Helmet>
        {/* <script async src={props.src} crossOrigin="anonymous"></script> */}
        {/* {props.showAdScript === true ? (
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6150950597865422"
            crossOrigin="anonymous"
          ></script>
        ) : (
          <script async crossOrigin="anonymous"></script>
        )} */}
        <title>{props.title}</title>
        <meta
          name="description"
          content="Our platform is a hub of
            knowledge, offering a diverse range of online courses that cater
            to your interests and ambitions"
        />
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <link rel="canonical" href="https://thecodehub.net" />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website"></meta>
        <meta name="author" content="The Code Hub" />
        <meta property="og:title" content={props.title} />
        <meta
          property="og:description"
          content="Our platform is a hub of
            knowledge, offering a diverse range of online courses that cater
            to your interests and ambitions."
        />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="https://www.thecodehub.net/" />
      </Helmet>
    </>
  );
};

export default MetaTag;
